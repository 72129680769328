import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { DataServiceService } from '../services/data-service.service';
import { SharedServiceService } from './../services/shared-service.service';

@Component({
  selector: 'app-ourthoughts',
  templateUrl: './ourthoughts.component.html',
  styleUrls: ['./ourthoughts.component.scss']
})
export class OurthoughtsComponent extends ParentComponent implements OnInit {
  public isOpen = false;
  public isGartner = false;
  public isDgitalscaletransformation = false;
  public componentName: any;
  @Output() modalOpen = new EventEmitter();
  constructor(public dataService: DataServiceService, private sharedService: SharedServiceService) { super(dataService); }

  ngOnInit() {
  }
  open_popover(componentname) {
    this.isOpen = true;
    this.componentName = componentname;
    if (componentname === 'gartner') {
      this.isGartner = true;
      this.isDgitalscaletransformation = false;
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`open_retail_banking`, 6, recordId);
    } else if (componentname === 'digitalscaletransformation') {
      this.isGartner = false;
      this.isDgitalscaletransformation = true;
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`open_our_digital_scale_transformation_grid`, 6, recordId);
    }
    this.modalOpen.emit(this.isOpen);
  }
  close_popover() {
    if (this.componentName === 'gartner') {
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`close_retail_banking`, 6, recordId);
    } else if (this.componentName === 'digitalscaletransformation') {
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`close_our_digital_scale_transformation_grid`, 6, recordId);
    }
    this.isOpen = false;
    this.isGartner = false;
    this.isDgitalscaletransformation = false;
    this.modalOpen.emit(this.isOpen);
  }
}
