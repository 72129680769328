import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataServiceService {
  public obs = new Subject();
  public obs$ = this.obs.asObservable();
  public navtitleSub: BehaviorSubject<string> = new BehaviorSubject<string>(
    "Initial Value"
  );
  navtitle: Observable<string> = this.navtitleSub.asObservable();
  public contextPath =
    window.location.hostname === "localhost"
      ? "https://bfslabdev.cognizant.com"
      : `https://${window.location.hostname}`;
  constructor(private http: HttpClient) {}

  getAllSolutions() {
    return this.http.get("assets/solutions.json").pipe(shareReplay());
  }
  getAllIplSolutions() {
    return this.http.get("assets/iplsolutions.json").pipe(shareReplay());
  }
  getAllIplVideos() {
    return this.http.get("assets/iplVideos.json").pipe(shareReplay());
  }
  getAllIdeas() {
    return this.http.get("assets/ideas.json").pipe(shareReplay());
  }
  getAllTags() {
    return this.http.get("assets/tags.json").pipe(shareReplay());
  }
  getAllLTTags() {
    return this.http.get("assets/LTTags.json").pipe(shareReplay());
  }
  getAllStoriesTags() {
    return this.http.get("assets/storiesTags.json").pipe(shareReplay());
  }
  getAllgenAIThinkingData() {
    return this.http.get("assets/genAIThinking.json").pipe(shareReplay());
  }
  getAllgenAISolutionData() {
    return this.http.get("assets/genAISolution.json").pipe(shareReplay());
  }
  getAllgenAIThinkingTags() {
    return this.http.get("assets/ourThinkingTags.json").pipe(shareReplay());
  }
  getAllgenAISolutionTags() {
    return this.http.get("assets/ourSolutionTags.json").pipe(shareReplay());
  }
  getAllThinkings() {
    return this.http.get("assets/thinkings.json").pipe(shareReplay());
  }
  getAllSuccessStories() {
    return this.http.get("assets/successStories.json").pipe(shareReplay());
  }
  getUserInfo(isMailer?: any) {
    return this.http.get(`${this.contextPath}/services/getuserinfo`);
  }

  saveVideoInfo(data: any) {
    return this.http.post(
      `${this.contextPath}/services/savevideodetails`,
      data
    );
  }
  getAllEvents() {
    return this.http.get("assets/events.json").pipe(shareReplay());
  }

  registerJourney(data) {
    //this.pagetitle=data.request.labelId;
    console.log(data);
    this.navtitleSub.next(data.request.labelId);
    //return this.http.post(`${this.contextPath}/services/savelabel`, data);
  }

  saveUserDetails(data) {
    return this.http.post(`${this.contextPath}/services/saveuserdetails`, data);
  }

  private subject = new Subject();

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
