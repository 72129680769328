import { GartnerPopoverComponent } from './../gartner-popover/gartner-popover.component';
import { DigitalScaleTransformationComponent } from './../digital-scale-transformation/digital-scale-transformation.component';
import { GartnerComponent } from './../gartner/gartner.component';
import { OwlCarouselComponent } from './../owl-carousel/owl-carousel.component';
import { CampaignComponent } from './../campaign/campaign.component';
import { SolutionLayerComponent } from './../solution-layer/solution-layer.component';
import { IdeabankComponent } from './../ideabank/ideabank.component';
import { HeaderMobileComponent } from './../header-mobile/header-mobile.component';
import { MobileHomeComponent } from './../mobile-home/mobile-home.component';
import { FormattagsPipe } from './../pipes/formattags.pipe';
import { MultiLineElipsisPipe } from './../pipes/multi-line-elipsis.pipe';
import { NavigationComponent } from './../navigation/navigation.component';
import { CounterDirective } from './../directives/counter.directive';
import { ContactusComponent } from './../contactus/contactus.component';
import { HorizontalDragDirective } from './../directives/horizontal-drag.directive';
import { Components } from './../materialcomponents';
import { MdePopoverModule } from '@material-extended/mde';
import { PivotComponent } from './../pivot/pivot.component';
import { WorkComponent } from './../work/work.component';
import { SolutionsComponent } from './../solutions/solutions.component';
import { DataServiceService } from './../services/data-service.service';
import { HeaderModule } from './../header/header.module';
import { HomeModule } from './../home/home.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { AboutModule } from '../about/about.module';
import { CatalogComponent } from '../catalog/catalog.component';
// import { MapComponent } from '../map/map.component';
// import { MapeventsComponent } from '../mapevents/mapevents.component';
import { DatashareService } from './../services/datashare.service';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { VideoCarouselComponent } from '../video-carousel/video-carousel.component';
import { HowcanwehelpComponent } from './../howcanwehelp/howcanwehelp.component';
import { SuggestionsComponent } from '../suggestions/suggestions.component';
import { VideoSreenComponent } from '../video-sreen/video-sreen.component';
import { OurthoughtsComponent } from '../ourthoughts/ourthoughts.component';
import { TheloftComponent } from '../theloft/theloft.component';
import { ConductahackathonComponent } from '../conductahackathon/conductahackathon.component';
import { CompetingmarketsComponent } from '../competingmarkets/competingmarkets.component';
import { HomeComponent } from '../home/home.component';
import { SharedModule } from '../shared/shared.module';
import { ContactformComponent } from '../contactform/contactform.component';

@NgModule({
  imports: [
    CommonModule,
    HomeModule,
    AboutModule,
    HeaderModule,
    SharedModule,
    ...Components,
    //MdePopoverModule,
    FormsModule,
    Ng2CarouselamosModule,
    LayoutModule,
    RouterModule.forRoot([])
  ],
  declarations: [
    MainComponent,
    CatalogComponent,
    SolutionsComponent,

    //WorkComponent,
    //VideoCarouselComponent,
    //  HorizontalDragDirective,
    PivotComponent,
    ContactusComponent,
    CounterDirective,
    NavigationComponent,
    MultiLineElipsisPipe,
    //FormattagsPipe,
    MobileHomeComponent,
    HeaderMobileComponent,
    IdeabankComponent,
    SolutionLayerComponent,
    CampaignComponent,
    //OwlCarouselComponent,
    DigitalScaleTransformationComponent,
    GartnerComponent,
    //HowcanwehelpComponent,
    //SuggestionsComponent,
    VideoSreenComponent,
    GartnerPopoverComponent,
    OurthoughtsComponent,
    TheloftComponent,
    ConductahackathonComponent,
    CompetingmarketsComponent,
    ContactformComponent
  ],
  providers: [DataServiceService, DatashareService],
  exports: [MainComponent, FormsModule]
})
export class MainModule { }
