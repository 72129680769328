import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  logout() {
    sessionStorage.removeItem('currentUser');
    window.location.href = "https://login.microsoftonline.com/de08c407-19b9-427d-9fe8-edf254300ca7/oauth2/logout";
    // this.route.navigate(['/login']);
  }
}
