import { trigger, style, animate, transition, state, query, stagger, keyframes } from '@angular/animations';

export const fade = [
    trigger('fade', [
        state('in', style({ 'opacity': '1' })),
        state('out', style({ 'opacity': '0' })),
        transition('* <=> *', [
            animate(500)
        ])
    ]),
    trigger('listAnimation', [
        transition('* => *', [

            query(':enter', style({ opacity: 0 }), { optional: true }),

            query(':enter', stagger('1000ms', [
                animate('0.5s ease-in', keyframes([
                    style({ opacity: 0 }),
                    style({ opacity: .5 }),
                    style({ opacity: 1 }),
                ]))]), { optional: true })
        ])
    ])
];
