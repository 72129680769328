import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, keyword?: any): any {
    if (!keyword || keyword.length === 0 || !value) {
      return value;
    }
    keyword = keyword.replace(/_/g, ' ').split(',');
    const contains = (s, a) => a.every(w => s.includes(w));
    const keywordArray = keyword.map((a) => a.toLowerCase());
    // const searchvalue = keyword[keyword.length - 1].value.toLowerCase();
    return value.filter((val) => {
      if (contains(val.tags.toLowerCase().split(','), keywordArray)) {
        return val;
      }
    });
  }

}
