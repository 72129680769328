import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {

  constructor() { }
  @Input() data;
  @Output() close = new EventEmitter();
  ngOnInit() {
  }
  closepopover() {
    this.close.emit(true);
  }

}
