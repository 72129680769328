import { MainModule } from "./main/main.module";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule, MatNativeDateModule } from "@angular/material";
import { NgxSpinnerModule } from "ngx-spinner";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { ParentComponent } from "./parent/parent.component";
import { WorkComponent } from "./work/work.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { MainComponent } from "./main/main/main.component";
import { FormsModule } from "@angular/forms";
import { ContactusComponent } from "./contactus/contactus.component";
import { BookalabComponent } from "./bookalab/bookalab.component";
import { ConductahackathonComponent } from "./conductahackathon/conductahackathon.component";
import { CallbackComponent } from "./callback/callback/callback.component";
import { InterceptorService } from "./services/interceptor/interceptor.service";
import { AuthGuardService } from "./services/auth-guard/auth-guard.service";
import { MatStepperModule } from "@angular/material/stepper";
import { SolutionsComponent } from "./solutions/solutions.component";
import { MatTabsModule } from "@angular/material/tabs";
//import {MatGridListModule} from '@angular/material';
// import { CompetingmarketsComponent } from './competingmarkets/competingmarkets.component';

// import { OurthoughtsComponent } from './ourthoughts/ourthoughts.component';
// import { HowcanwehelpComponent } from './howcanwehelp/howcanwehelp.component';
// import { GartnerComponent } from './gartner/gartner.component';

const appRoutes: Routes = [
  //{ path: "", component: MainComponent, pathMatch: "full" },
  { path: "", component: LoginComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "solutions", component: SolutionsComponent },

  // { path: "home", component: MainComponent, canActivate: [AuthGuardService] },
  // { path: "contactUs", component: ContactusComponent, canActivate: [AuthGuardService] },
  // { path: "", component: MainComponent, pathMatch: "full" },
  { path: "home", component: MainComponent },
  { path: "contactUs", component: ContactusComponent },
  { path: "callback", component: CallbackComponent },
];

@NgModule({
  declarations: [
    AppComponent,

    FilterPipe,
    ParentComponent,
    LoginComponent,
    CallbackComponent,
    //ContactformComponent

    //ConductahackathonComponent,
    //BookalabComponent,
    // CompetingmarketsComponent,

    // HowcanwehelpComponent
    // GartnerComponent
  ],
  imports: [
    BrowserModule,
    MatFormFieldModule,
    MainModule,
    HttpClientModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    NgxSpinnerModule,
    //MatGridListModule,
    MatStepperModule,
    MatTabsModule,
  ],
  providers: [
    WorkComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
