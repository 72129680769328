import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { TokenSet } from "authlibrary/src/types/OpenidConnectClient";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthWithOAuthService {
  public SessionTokens: TokenSet = {};
  public token = new BehaviorSubject<TokenSet>(this.SessionTokens);
  public userInfoToken;
  public httpOptions: any;
  public clientId: string = "72946f9c-0d02-4a23-b228-e7a5e3579acc";
  public tenantId: string = "de08c407-19b9-427d-9fe8-edf254300ca7";

  constructor(private http: HttpClient, private router: Router) {}

  public getAuthorizeURL(): any {
    return (
      "https://login.microsoftonline.com/" +
      this.tenantId +
      "/oauth2/authorize?redirect_uri=https%3A%2F%2Ffsinextinsurance.cognizant.com%2Fcallback&client_id=" +
      this.clientId +
      "&response_type=code&state=STATE&scope=openid%20email%20profile"
    );
  }

  getIdToken() {
    let idToken;
    if (sessionStorage.getItem("currentUser")) {
      idToken = JSON.parse(sessionStorage.getItem("currentUser") || "").token
        .id_token;
    }
    return idToken;
  }

  getUserNameId(idToken) {
    let encodedPayload = idToken.split(".")[1];
    let idTokenPayload = JSON.parse(atob(encodedPayload));
    let username = idTokenPayload.name;
    let emailId = idTokenPayload.unique_name;
    let userId = emailId.split("@")[0];
    sessionStorage.setItem("emailId", emailId);
    sessionStorage.setItem("userName", username);
    sessionStorage.setItem("userId", userId);
  }

  public getBearerTokenAzure(code, codeVerifier) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    this.http
      .post(
        "https://gag6jl5kei.execute-api.us-east-1.amazonaws.com/prod/azuregettoken?action=getToken&grantType=authorization_code&code=" +
          code +
          "&codeVerifier=" +
          codeVerifier,
        this.httpOptions
      )
      .subscribe({
        next: (data) => {
          var tokenJSON = {
            access_token: data["access_token"],
            token_type: data["token_type"],
            id_token: data["id_token"],
            refresh_token: data["refresh_token"],
            expires_in: +data["expires_in"],
            expires_at: +data["expires_on"],
            // session_state: tokenJSON.session_state,
            // scope: tokenJSON.scope
          };
          if (tokenJSON.id_token != undefined) {
            this.userInfoToken = tokenJSON;
            this.getUserNameId(tokenJSON.id_token);
            sessionStorage.setItem(
              "currentUser",
              JSON.stringify({ expiry: tokenJSON.expires_at })
            );
            this.SessionTokens = tokenJSON;
            this.token.next(tokenJSON);
            // this.getUserEmail(tokenJSON.id_token);
            this.router.navigate(["home"]);
          } else {
            tokenJSON.id_token = this.userInfoToken.id_token;
            this.SessionTokens = tokenJSON;
            sessionStorage.setItem(
              "currentUser",
              JSON.stringify({ token: tokenJSON })
            );
            this.token.next(tokenJSON);
            // this.getUserEmail(this.userInfoToken.id_token);
            this.router.navigate(["home"]);
          }
        },
        error: (error) => console.error("There was an error!", error),
      });
  }
}
