import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gartner-popover',
  templateUrl: './gartner-popover.component.html',
  styleUrls: ['./gartner-popover.component.scss']
})
export class GartnerPopoverComponent implements OnInit {
  @Input() selectedHeading: any;
  @Input() headingPoints: any;
  constructor() { }

  ngOnInit() {
  }

}
