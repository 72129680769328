import { Injectable } from '@angular/core';

@Injectable()
export class DatashareService {
  private _setMonth: any = [];
  private _setMonthCount: any = [];
  private _setRegion: any = [];
  private _setRegionCount: any = [];

  constructor() { }

  setMothName(data) {
    this._setMonth.push(data);
  }

  getMonthName() {
    return this._setMonth;
  }

  setMothNameforCount(data) {
    this._setMonthCount.push(data);
  }

  getMonthNameforCount() {
    return this._setMonthCount;
  }

  setRegionName(data) {
    this._setRegion.push(data);
  }

  getRegionName() {
    return this._setRegion;
  }

  setRegionNameforCount(data) {
    this._setRegionCount.push(data);
  }

  getRegionNameforCount() {
    return this._setRegionCount;
  }



}
