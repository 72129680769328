import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormattagsPipe } from '../pipes/formattags.pipe';
import { HomeComponent } from '../home/home.component';
import { VideoCarouselComponent } from '../video-carousel/video-carousel.component';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { MdePopoverModule } from '@material-extended/mde';
import { WorkComponent } from '../work/work.component';
import { OwlCarouselComponent } from '../owl-carousel/owl-carousel.component';
import { SuggestionsComponent } from '../suggestions/suggestions.component';
import { HowcanwehelpComponent } from '../howcanwehelp/howcanwehelp.component';
import { HorizontalDragDirective } from '../directives/horizontal-drag.directive';


@NgModule({
  imports: [
    CommonModule,
    MdePopoverModule,
    //Ng2CarouselamosModule,
  ],
  declarations: [FormattagsPipe,
    VideoCarouselComponent,
    WorkComponent,
    //HomeComponent,
    OwlCarouselComponent,
    SuggestionsComponent,
    HowcanwehelpComponent,
    HorizontalDragDirective,
  ],
  exports: [
    FormattagsPipe,
    CommonModule,
    MdePopoverModule,
    VideoCarouselComponent,
    WorkComponent,
    HorizontalDragDirective,
    OwlCarouselComponent
  ]
})
export class SharedModule { }
