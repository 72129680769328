import { Component, OnInit } from '@angular/core';
import { fade } from './animation';

@Component({
  selector: 'app-solution-layer',
  templateUrl: './solution-layer.component.html',
  styleUrls: ['./solution-layer.component.scss'],
  animations: fade
})
export class SolutionLayerComponent implements OnInit {
  public solutions;
  public selectedSolution;
  public mob_max = '768px';
  public max_width = '1399px';
  public min_width = '1501px';
  public src = 'illustartions.png';
  public toltipText: any;
  selected = 'Retail Banking';

  state = 'in';
  counter = 0;
  imageSource;
  enableAnimation = false;
  innerData;
  tooltip;
  constructor() { }

  ngOnInit() {

    this.solutions = [
      {
        'name': 'Personal finance manager',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Personalized and Real time management of customers financial requirements', value: 'ce' },
          { 'text': 'Financial health monitoring, Spend advisor, Spend analysis, Investment advisor', value: 'application' },
          { 'text': 'Peer performance, Recommend products, Spend advisory based on specific Amount/Location/Goals, Managing Portfolios', value: 'service' },
          { 'text': 'Customer existing financial information, NASDAQ 200 stocks data', value: 'data' },
          { 'text': 'IBM Watson financial services for Risk vs. Reward Analysis', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
      {
        'name': 'Voice banking',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Convenient & Fast voice based banking transactions for customers', value: 'ce' },
          { 'text': 'Chat bot based customer\'s banking transactions', value: 'application' },
          { 'text': 'Monitoring of Portfolio & Tracking Account balance, Bill payments, Transfers, Statements', value: 'service' },
          { 'text': 'Customer\'s existing Financial/Banking information', value: 'data' },
          { 'text': 'Google Dialogflow', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
      {
        'name': 'Paperless on-boarding',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Easy and Time saving self on-boarding by the customer', value: 'ce' },
          { 'text': 'Paperless customer on-boarding using their smartphone/laptop', value: 'application' },
          { 'text': 'Customer\'s Contact / Address / Identity details verification, Cross selling of products, Google API', value: 'service' },
          { 'text': 'Account creation, Feeding Personal/Employment details, Identity/Social media verification', value: 'data' },
          { 'text': 'Microsoft Cognitive Services - Facial biometrics, Optical character recognition, Google mobile vision', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
      {
        'name': 'Customer branch experience',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': '360 degree view of Client\'s Branch journey, Need analysis & Transactions', value: 'ce' },
          { 'text': 'Real time client Monitoring & client\'s need identification within Bank premises', value: 'application' },
          { 'text': 'Real time client journey monitoring, Core banking system, Personalized offers, Feedback', value: 'service' },
          { 'text': 'Data gathered across various bank branches across geographies', value: 'data' },
          { 'text': 'Beacons based client\'s time consumption monitoring at a location', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS,  BLE beacons', value: 'infra' }
        ]
      },
      {
        'name': 'Loyalty reward program',
        'image': ['lrp_md', 'lrp'],
        'desc': [
          { 'text': 'Seamless & Personalized experience for Loyalty reward program', value: 'ce' },
          { 'text': 'Personalized, Cost efficient, Time efficient Loyalty reward programs', value: 'application' },
          { 'text': '', value: 'service' },
          { 'text': 'Merchants and Clients past transaction data', value: 'data' },
          { 'text': 'Analytics dashboard based Points spent for Merchants & Amount spent  for Customer', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS, Corda', value: 'infra' }
        ]
      },
      {
        'name': 'Omni channel journey manager',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Customers anytime anywhere banking journey monitoring over various devices/channels', value: 'ce' },
          { 'text': 'RM receives customers multiple touchpoints in banking journey to monitor Digital leakage', value: 'application' },
          { 'text': 'Banking, like applying for Credit/Debit card, Loan application, etc.', value: 'service' },
          { 'text': 'Application event tracking across channels', value: 'data' },
          { 'text': 'Data analyzed over time - to monitor digital leakage for optimizing the process', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
      {
        'name': 'Advisory assistant',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Smarter and Time efficient way for RM to manage their tasks', value: 'ce' },
          { 'text': 'NLP based Virtual Assistant to aid RM\'s day to day tasks', value: 'application' },
          { 'text': 'Manage Appointments & Information on Reports, Articles, Stocks', value: 'service' },
          { 'text': 'Customers stored data', value: 'data' },
          { 'text': 'Google Dialogflow, Linear regression', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
      {
        'name': 'Contextualized offer management',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Personalized and Engaging AR based offers for customers', value: 'ce' },
          { 'text': 'Customer relevant offers from Shopping, Travel, Fitness, Food, Gifts categories', value: 'application' },
          { 'text': 'Offer coupons, GPS based navigation', value: 'service' },
          { 'text': 'Historic consumption patterns from transaction data', value: 'data' },
          { 'text': 'Customer spending Categorization/ Segmentation to determine best offers', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS, Android platform', value: 'infra' }
        ]
      },
      {
        'name': 'Smart website',
        'image': ['pfm_md', 'pfm'],
        'desc': [
          { 'text': 'Personalized and Interactive Banking for customers', value: 'ce' },
          { 'text': 'Chat bot based banking transactions', value: 'application' },
          { 'text': 'Banking - Apply for credit/debit cards, Loan repayment, Resolution of routine queries', value: 'service' },
          { 'text': 'Customers past banking information', value: 'data' },
          { 'text': 'Google Dialogflow', value: 'analyst' },
          { 'text': 'Amazon services like EC2, RDS', value: 'infra' }
        ]
      },
    ];
    this.selectedSolution = this.solutions[0];
    this.imageSource = this.solutions[0].image;
    this.tooltip = this.solutions[0].desc;
  }

  setSolutionImage(sol) {
    this.tooltip = [];
    this.imageSource = sol.image;
    setTimeout(() => {
      sol.desc.map((v) => {
        this.tooltip.push(v);
      });
    }, 1000);
  }
}
