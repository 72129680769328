import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { fromEvent, empty } from 'rxjs';
import { map, concatMap, takeUntil, elementAt, catchError, merge, tap } from 'rxjs/operators';
@Component({
  selector: 'app-mobile-home',
  templateUrl: './mobile-home.component.html',
  styleUrls: ['./mobile-home.component.scss']
})
export class MobileHomeComponent implements OnInit {
  @ViewChild('swipeMe') swipeMe: ElementRef;
  public activeSlide = 0;
  constructor() { }

  ngOnInit() {

    const mouseEventToCoordinate = mouseEvent => {
      return {
        x: mouseEvent.clientX,
        y: mouseEvent.clientY,
        ev: mouseEvent
      };
    };

    const touchEventToCoordinate = touchEvent => {
      return {
        x: touchEvent.changedTouches[0].clientX,
        y: touchEvent.changedTouches[0].clientY,
        ev: touchEvent
      };
    };

    const mouseDowns = fromEvent(this.swipeMe.nativeElement, 'mousedown').pipe(
      map(mouseEventToCoordinate)
    );

    const mouseMoves = fromEvent(this.swipeMe.nativeElement, 'mousemove').pipe(
      map(mouseEventToCoordinate)
    );
    const mouseUps = fromEvent(this.swipeMe.nativeElement, 'mouseup').pipe(
      map(mouseEventToCoordinate)
    );
    const touchStarts = fromEvent(this.swipeMe.nativeElement, 'touchstart').pipe(
      map(touchEventToCoordinate)
    );
    const touchMoves = fromEvent(this.swipeMe.nativeElement, 'touchmove').pipe(
      map(touchEventToCoordinate)
    );
    const touchEnds = fromEvent(this.swipeMe.nativeElement, 'touchend').pipe(
      map(touchEventToCoordinate)
    );

    const starts = mouseDowns.pipe(
      merge(touchStarts)
    );
    const moves = mouseMoves.pipe(
      merge(touchMoves)
    );
    const ends = mouseUps.pipe(
      merge(touchEnds)
    );




    starts.pipe(
      concatMap((dragStartEvent) => {
        return moves.pipe(
          takeUntil(ends),
          elementAt(3),
          catchError(err => empty()),
          map(ev => {
            const intialDeltaX = ev.x - dragStartEvent.x;
            const initialDeltaY = ev.y - dragStartEvent.y;
            return { x: dragStartEvent.x, y: dragStartEvent.y, intialDeltaX, initialDeltaY, event: ev.ev };
          })
        );
      })
    ).subscribe(res => {
      if (Math.abs(res.initialDeltaY) < Math.abs(res.intialDeltaX)) {/*most significant*/
        if (res.intialDeltaX > 0) {
          this.swipe(0);
        } else {
          /* right swipe */
          this.swipe(1);
        }
      }
    });


  }
  swipe(i) {
    const carousel_container = document.querySelector('.carousel__wrapper');
    const left = i * document.body.scrollWidth;
    this.activeSlide = i;
    carousel_container['style'].left = `-${left}px`;
  }
}
