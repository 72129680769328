import { SharedServiceService } from "./../services/shared-service.service";
import { DataServiceService } from "./../services/data-service.service";
import { AppComponent } from "../app.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import {
  trigger,
  style,
  transition,
  animate,
  keyframes,
  query,
  stagger,
  state,
} from "@angular/animations";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  EventEmitter,
  Output,
  AfterViewInit,
  Input,
  ContentChild,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { ParentComponent } from "../parent/parent.component";
import { switchMap, map } from "rxjs/operators";
import { title } from "process";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { saveAs } from "file-saver";
declare const $: any;
declare const ga: any;
declare const yam: any;
declare const Hls: any;

// declare const PDFJS: any;
@Component({
  selector: "app-solutions",
  templateUrl: "./solutions.component.html",
  styleUrls: [
    "./solutions.component.scss",
    "solution_desktop.component.scss",
    "solutions_mobile.components.scss",
  ],
  animations: [
    trigger("listAnimation", [
      transition("* => *", [
        query(":enter", style({ opacity: 0 }), { optional: true }),
        query(
          ":enter",
          stagger("200ms", [
            animate(
              "0.5s ease-in",
              keyframes([
                style({ opacity: 0 }),
                style({ opacity: 0.5 }),
                style({ opacity: 1 }),
              ])
            ),
          ]),
          { optional: true }
        ),
      ]),
    ]),
    trigger("scrollAnimation", [
      state(
        "show",
        style({
          transform: "translateX(-50%)",
        })
      ),
      state(
        "hide",
        style({
          transform: "translateX(0%)",
        })
      ),
      transition("show => hide", animate("700ms ease-out")),
      transition("hide => show", animate("700ms ease-in")),
    ]),
  ],
})
export class SolutionsComponent
  extends ParentComponent
  implements OnDestroy, OnInit, AfterViewInit
{
  state = "hide";
  togValue: (i: any, e: any) => void;
  event: any;
  status: any;
  inActive: any;
  tagName: string = "All";
  infoData: any;
  active: any;
  public isOpen = false;
  private ngUnsubscribe = new Subject<void>();
  @Input() tab;
  @ViewChild("videoPlayer")
  public videoPlayer: ElementRef;
  @ViewChild("videoPlayer1")
  public videoPlayer1: ElementRef;
  @ViewChild("videoPlayer2")
  public videoPlayer2: ElementRef;
  @ViewChild("videoPlayer3")
  public videoPlayer3: ElementRef;
  thinkingDragWidth: any;
  selectedTab: number;

  data: any = {};
  name = "";
  email = "";
  formData: any = {};

  alertsuccess: boolean = false;
  alerterror: boolean = false;
  options: any = { year: "numeric", month: "long", day: "numeric" };
  result: any = {};
  responseId = "";
  message = "";
  minDate: any = "";

  @Output() close = new EventEmitter<string>();
  downloadPOV = new FormGroup({
    empID: new FormControl("", Validators.required),
    empName: new FormControl("", Validators.required),
    accName: new FormControl("", Validators.required),
    projectName: new FormControl("", Validators.required),
    purpose: new FormControl("", Validators.required),
  });
  userName: string;
  userId: string;

  constructor(
    private dataService: DataServiceService,
    private sharedService: SharedServiceService,
    private renderer: Renderer2,
    private router: Router,
    private pdfDownloadService: DataServiceService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {
    super(dataService);
    this.dataService.getMessage().subscribe((message) => {
      console.log(message);
      // this.selectedTab = 12;
      if (message.text === "yes") {
        this.selectTab(12);
      } else {
        this.selectTab(11);
      }
    });
  }

  onSubmit() {
    this.formData = {
      formName: "Download-POV",
      // userAccount: this.downloadPOV.get("accounthackathon").value,
      // usercp: this.downloadPOV.get("cpname").value,
      accName: this.downloadPOV.get("accName").value,
      projectName: this.downloadPOV.get("projectName").value,
      purpose: this.downloadPOV.get("purpose").value,
      username: sessionStorage.getItem("userName"),
      userEmployeeId: sessionStorage.getItem("userId"),
    };
    if (this.downloadPOV.valid) {
      console.log(this.formData);
      console.log("form submitted");
      this.downloadPdf(this.infoPanelData.pdf, this.infoPanelData.filename);
      this.alertsuccess = true;
      this.message = "Download Completed";
      this.closeAlertError();
      // this.http
      //   .post(
      //     "https://hhxa8qsu2m.execute-api.us-east-1.amazonaws",
      //     this.formData
      //   )
      //   .subscribe((response) => {
      //     this.result = response;
      //     this.responseId = JSON.stringify(this.result.MessageId);
      //     if (!this.responseId) {
      //       this.alerterror = true;
      //       this.closeAlertSuccess();
      //       this.message = "Something went wrong,Please try again later";
      //     } else {
      //       this.alertsuccess = true;
      //       this.message =
      //         "Form Submitted Successfully! We will contact you shortly.";
      //       this.closeAlertError();
      //     }
      //   });
      setTimeout(() => {
        this.close.emit();
      }, 4000);
      // this.downloadPOV.reset();
    } else {
      // validate all form fields
      console.log("form not valid");
    }
  }
  closeAlertSuccess() {
    this.alertsuccess = false;
  }
  closeAlertError() {
    this.alerterror = false;
  }

  tiles = [
    { text: "One", cols: 3, rows: 2, color: "#fff", elipsisSize: 45 },
    {
      text: "Two",
      cols: 2,
      rows: 4,
      color: "#fff",
      className: "two-by-one",
      elipsisSize: 60,
    },
    {
      text: "Three",
      cols: 1,
      rows: 1,
      color: "#fff",
      className: "one-by-one",
      elipsisSize: 45,
    },
    { text: "One", cols: 2, rows: 2, color: "#fff", elipsisSize: 45 },
    {
      text: "Two",
      cols: 2,
      rows: 1,
      color: "#fff",
      className: "two-by-one",
      elipsisSize: 60,
    },
    {
      text: "Three",
      cols: 1,
      rows: 1,
      color: "#fff",
      className: "one-by-one",
      elipsisSize: 45,
    },
  ];

  public videoOptions = {
    items: 1,
    dots: false,
    autoplay: false,
    autoplayHoverPause: true,
    video: true,
  };
  public isDemo = false;
  randomizedTileSizesIndex: any = [];
  public toggleValue: string = "Active";
  public defaultToggle: any;
  solutions: any;
  solutionLength: any;
  allSolutions: any;
  originalArray: any;
  iplsolutiontabClicked = false;
  iplSolutions: any;
  iplOffsetWidth: number;
  iplScrollWidth: number;
  disableLeftArrow = true;
  disableRightArrow = false;
  @ViewChild("solutionWrapper") solutionWrapper: ElementRef;
  iplVideos: any;
  iplYear: number = 2022;
  yearWiseIplSolutions = [];
  YearWiseIplVideos = [];
  iplInfoPanel = false;
  iplJourneyImagePopup = false;
  infoPanelData: any;

  screenwidth: number;
  dragCount: number;
  dragCountLimit: any = [4, 8];
  gridRowHeight: string;
  gridWidthOffset: number;
  gutterSize: number;
  colNumOffset: number;
  colNumner: number;
  totCols: number;
  dragValue: number;
  dragWidth: number;
  isInfoPanelVisible: boolean;
  videoDomain: string;
  focusArea: string;
  thinkingFocusArea: any;
  solutionFocusArea: any;
  filterTags: any;
  ltFilterTags: any;
  storiesFilterTags: any;
  genAIThinkingTags: any;
  genAISolutionTags: any;
  genAITabIndex: any = 1;
  activeTabIndex: number;
  screenHeight: any;
  selectedPdf: any;
  public isPlaying = false;
  public isPlayingCarousel = false;
  isDraggingStoped: boolean;
  loading: boolean;
  yammer = false;
  public recordId;
  public src;
  public bb_src;
  public wm_src;
  public bb_s_src;
  public mob_max = "768px";
  public max_width = "1400px";
  public min_width = "1401px";
  public advice = false;
  private _user;
  public open_cp = false;
  public open_bb = false;
  public open_wm = false;
  public ideas$: Observable<any>;
  public cogni_thumb = "cogni-thumb";
  public isVideoPlaying = false;
  public width1366 = false;
  public stateContactUs = false;
  @ViewChild("videoSP") videoSP;
  @ViewChild("video") video;
  @ContentChild("innervideo") innervideo;
  @ViewChild("gridList") gridList: ElementRef<any>;
  @Output() move = new EventEmitter<any>();
  @Output() modalOpen = new EventEmitter();
  @ViewChild("widgetsContent", { read: ElementRef })
  public widgetsContent: ElementRef<any>;

  tabselection = {
    label_nav_our_catalog_solutionCatalog: 11,
    label_nav_our_catalog_Hackathon: 10,
    label_nav_our_catalog_POV: 1,
    label_nav_our_catalog_successStories: 2,
    label_nav_our_catalog_genAI: 12,
  };

  /****/
  public transform;
  selectedIndex = 1;
  index = 1;
  @ViewChild("scrollMe") scrollMe: ElementRef;
  /****/

  sols = {
    attributes: [
      { name: "Toggle", type: "String", value: "Active", count: " " },
    ],
  };

  setValue(i, e) {
    if (e.checked) {
      this.colNumner = 59;
      console.log(
        "Active Status This is set value colnum=59",
        this.allSolutions
      );
      this.sols.attributes[i].value = "Active";
      this.defaultToggle = this.sols.attributes[i].value;
      this.toggleValue = "Active";
      this.active = this.allSolutions.filter(
        (s) => s.tagName.includes(this.tagName) && s.status == "active"
      );
      this.solutions = this.active;
      this.sols.attributes[i].count = this.solutions.length;
      console.log("this solutions if", this.solutions);
    } else {
      this.colNumner = 27;
      console.log(
        "In Active Status This is set value colnum=27",
        this.allSolutions
      );
      this.sols.attributes[i].value = "In-Active";
      this.toggleValue = "Others";
      this.inActive = this.allSolutions.filter(
        (s) => s.tagName.includes(this.tagName) && s.status == "in-active"
      );

      this.solutions = this.inActive;
      this.sols.attributes[i].count = this.solutions.length;
      console.log("this solutions In-active", this.solutions);
    }
    this.arrangeGrid();
    this.changeFilter(this.tagName);
    return this.toggleValue;
  }

  toggleCP(label) {
    this.advice = !this.advice;
    this.state = "hide";
    const recordId = this.sharedService.getRecordId();
    this.saveHistory(label, 8, recordId);
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 350,
      behavior: "smooth",
    });
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 350,
      behavior: "smooth",
    });
  }

  setScreenSize() {
    this.screenwidth = $(window).width();
    this.screenHeight = $(window).height();
    console.log(this.screenHeight, "screen height");
    console.log(this.screenwidth, "screen width");
    this.gridRowHeight = "160px";
    this.gridWidthOffset = 145;
    this.colNumOffset = 4;
    this.gutterSize = 10;
    if (this.screenwidth < 361) {
      this.gridWidthOffset = 130;
      this.gutterSize = 10;
      this.gridRowHeight = "125px";
      this.colNumOffset = 4;
    } else if (this.screenwidth < 500) {
      this.gridWidthOffset = 130;
      this.gutterSize = 10;
      this.gridRowHeight = "130px";
      this.colNumOffset = 4;
    } else if (this.screenwidth < 700) {
      // landscape phone
      this.gridWidthOffset = 130;
      this.gridRowHeight = "130px";
      this.colNumOffset = 4;
      this.gutterSize = 10;
    } else if (this.screenwidth < 800) {
      this.gridWidthOffset = 130;
      this.gridRowHeight = "100px";
      this.colNumOffset = 4;
      this.gutterSize = 10;
    } else if (this.screenwidth < 1100) {
      this.gridRowHeight = "115px";
      this.gridWidthOffset = 134;
      this.colNumOffset = 4;
      this.gutterSize = 20;
    } else if (this.screenwidth <= 1400) {
      this.gridWidthOffset = 1006;
      this.gutterSize = 6;
      this.gridRowHeight = "110px";
      this.colNumOffset = 4;
      this.width1366 = true;
      console.log("1400");
    } else if (this.screenwidth < 1500) {
      this.gridWidthOffset = 195;
      this.gutterSize = 20;
      this.gridRowHeight = "160px";
      this.colNumOffset = 4;
      this.width1366 = true;
      //console.log("1500");
    } else if (this.screenHeight < 900) {
      this.gridRowHeight = "120px";
      this.gridWidthOffset = 145;
      this.colNumOffset = 4;
      this.gutterSize = 10;
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit() {
    // this.disablePastDate();
    // sessionStorage.setItem("userName", "B, Subash Chandar (Cognizant)");
    // sessionStorage.setItem("userId", "2128666");
    this.userId = sessionStorage.getItem("userId");
    this.userName = sessionStorage.getItem("userName");

    if (window.outerWidth <= 1500) {
      this.width1366 = true;
      this.src = "cp_xl";
      this.bb_src = "business-banking-xl";
      this.wm_src = "wm_wrapper_small";
      this.bb_s_src = "bb-service-catalogue-xl";
      this.cogni_thumb = "cogni-thumb-xl";
    } else if (window.outerWidth >= 1501) {
      this.width1366 = true;
      this.src = "cp_xxl";
      this.wm_src = "wm_wrapper";
      this.bb_src = "business-banking-xxl";
      this.bb_s_src = "bb-service-catalogue-xxl";
      this.cogni_thumb = "cogni-thumb";
    }
    console.log("Inside Init");
    this.setScreenSize();
    this.dragValue = 0;
    this.isInfoPanelVisible = false;
    this.loading = true;
    this.focusArea = "All";
    this.isDraggingStoped = true;
    this.videoDomain = this.sharedService.getDomain();
    this.dataService
      .getAllTags()
      .pipe(map((data) => ({ data })))
      .subscribe((response) => {
        this.filterTags = response.data;
        this.selectTab(11);
        // console.log('filter tags', this.filterTags.slice(0,6));
      });

    this.dataService.navtitle.subscribe((res) => {
      console.log("Behavioural Variable response", res);
      console.log("this.tabselection", this.tabselection);
      if (this.tabselection[res]) {
        console.log("tab selection", this.tabselection[res]);
        if (res.includes("nav_our_catalog")) {
          console.log("true");
          this.selectTab(this.tabselection[res]);
        }
      } else {
        // this.selectTab(0);
      }
    });

    this.dataService.getAllLTTags().subscribe((res) => {
      this.ltFilterTags = res;
      console.log(" this.ltFilterTags", this.ltFilterTags);
    });
    this.dataService.getAllStoriesTags().subscribe((res) => {
      this.storiesFilterTags = res;
    });
    this.dataService.getAllgenAIThinkingTags().subscribe((res) => {
      this.genAIThinkingTags = res;
      console.log(" this.genAITags", this.genAIThinkingTags);
    });
    this.dataService.getAllgenAISolutionTags().subscribe((res) => {
      this.genAISolutionTags = res;
      console.log(" this.genAITags", this.genAISolutionTags);
    });
    $(window).resize(() => {
      this.setScreenSize();

      // this.arrangeGrid();
      // this.changeFilter(this.tagName);
      if (this.selectedTab == 1) {
        this.setScreenSize();
        this.getTabData();
        this.arrangeGrid();
        this.changeFilter(this.tagName);
      } else {
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "160px";
          this.gridWidthOffset = 145;
          this.colNumOffset = 4;
          this.gutterSize = 10;
        } else if (this.screenwidth <= 1400) {
          console.log("1400-oninit");
          this.gridWidthOffset = 125;
          this.gutterSize = 8;
          this.gridRowHeight = "110px";
          this.colNumOffset = 4;
        }
      }
    });
  }

  ngAfterViewInit() {
    // console.log(this.user);
  }

  dragRight() {
    this.dragCount++;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`arrow_right`, 8, this.recordId);
    console.log(this.gridList.nativeElement);
    console.log(this.gridWidthOffset);
    $(this.gridList.nativeElement).animate(
      {
        scrollLeft:
          this.gridList.nativeElement.scrollLeft + this.gridWidthOffset * 2,
      },
      () => {
        console.log("drag Right +++");
        this.dragValue =
          this.gridList.nativeElement.scrollLeft +
          $(this.gridList.nativeElement).innerWidth();
        this.dragWidth = this.gridList.nativeElement.scrollWidth;
      }
    );
    console.log(
      "this.gridList.nativeElement.scrollLeft",
      this.gridList.nativeElement.scrollLeft
    );
    console.log("Right", this.dragWidth);
    console.log("right this.dragValue ", this.dragValue);
  }
  dragLeft() {
    this.dragCount--;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`arrow_left`, 8, this.recordId);
    console.log(this.gridList.nativeElement);
    console.log(this.gridWidthOffset);
    $(this.gridList.nativeElement).animate(
      {
        scrollLeft:
          this.gridList.nativeElement.scrollLeft - this.gridWidthOffset * 2,
      },
      () => {
        this.dragValue = this.gridList.nativeElement.scrollLeft;
        this.dragWidth = this.gridList.nativeElement.scrollWidth;
      }
    );
    console.log("Left", this.dragWidth);
    console.log("left this.dragValue ", this.dragValue);
  }

  selectTab(tabNumber) {
    // console.log("tabbbb",this.tab);
    if (tabNumber == 12) {
      this.genAITabIndex = 1;
    }
    console.log("TabNumber", typeof tabNumber);
    this.selectedTab = tabNumber;
    this.getTabData();
    this.sols.attributes[0].value = "Active";
    this.defaultToggle = "Active";
    //this.sols.attributes[0].count = '15';
    if (tabNumber === 11) {
      this.dataService.getAllSolutions().subscribe((resposnse) => {
        this.allSolutions = resposnse;
        // this.originalArray = JSON.parse(JSON.stringify(resposnse));
        // const contains = (s, a) => a.every(w => s.includes(w));
        // this.solutions = this.originalArray.filter((val) => {
        //   if (contains(val.tagName.map(res => res.trim().toLowerCase()), ['all'])) {
        //     return val;
        //   }
        // });
        //this.solutions =  this.solutions.filter(s => s.status == 'active');

        this.active = this.allSolutions.filter((s) => s.status == "active");
        this.solutions = this.active;

        this.sols.attributes[0].count = this.solutions.length;
      });
    }
  }
  getTabData() {
    this.loading = true;
    this.recordId = this.sharedService.getRecordId();
    console.log("RecordID", this.recordId);
    this.solutions = [];
    switch (this.selectedTab) {
      case 11:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "160px";
          this.gridWidthOffset = 145;
          this.colNumOffset = 4;
          this.gutterSize = 10;
          this.colNumner = 80;
          console.log("This is getTab Data colnum=59");
          console.log(this.colNumner, "no");
        } else if (this.screenwidth <= 1400) {
          console.log("1400-tabdata");
          this.gridWidthOffset = 1006;
          this.gutterSize = 6;
          this.gridRowHeight = "110px";
          this.colNumOffset = 20;
          this.colNumner = 80;
          console.log("This is getTab Data colnum=59");
          console.log(this.colNumner, "no-1366");
        }
        this.saveHistory("some_of_our_work", 8, this.recordId);
        // this.colNumner = 59
        this.focusArea = this.filterTags[0];
        //this.filterTagsActive = this.filterTags.slice(0,6);
        this.dataService.getAllSolutions().subscribe((resposnse) => {
          this.allSolutions = resposnse;
          //console.log("hi", this.allSolutions);
          this.originalArray = JSON.parse(JSON.stringify(resposnse));
          const contains = (s, a) => a.every((w) => s.includes(w));
          this.solutions = this.originalArray.filter((val) => {
            if (
              contains(
                /*val.tagName.map((res) => res.trim().toLowerCase()),
                ["all"]*/
                val.tagName.map((res) => res.trim().toLowerCase()),
                ["all"]
              )
            ) {
              return val;
            }
          });
          this.solutions = this.solutions.filter((s) => s.status == "active");
          //this.sols.attributes[0].count = this.solutions.length;

          this.arrangeGrid();
          this.toggleValue = "Active";
        });
        // this.loading = false;
        break;
      case 1:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }

        this.saveHistory("latest_thinking", 8, this.recordId);
        this.colNumner = 59;
        console.log("This is getTab Data case 1 colnum=59");
        console.log("this.ltFilterTags", this.ltFilterTags);
        this.focusArea = this.ltFilterTags[0];
        // this.dragWidth =  this.gridList.nativeElement.scrollWidth;
        this.dataService.getAllThinkings().subscribe((resposnse) => {
          this.solutions = resposnse;
          console.log("this.solutions", this.solutions);
          this.originalArray = JSON.parse(JSON.stringify(resposnse));
          this.arrangeGrid();
        });
        break;
      case 2:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }

        this.saveHistory("idea_bank", 8, this.recordId);
        this.colNumner = 59;
        console.log("This is getTab Data case 1 colnum=59");

        this.focusArea = this.storiesFilterTags[0];
        // this.dragWidth =  this.gridList.nativeElement.scrollWidth;
        this.dataService.getAllSuccessStories().subscribe((resposnse) => {
          this.solutions = resposnse;
          this.originalArray = JSON.parse(JSON.stringify(resposnse));
          this.arrangeGrid();
        });
        break;
      // case 3:
      //   this.colNumner = 5;
      //   console.log("This is getTab Data case 3 colnum=5");
      //   this.saveHistory("campaign", 8, this.recordId);
      //   // this.dataService.getAllIdeas().subscribe(resposnse => {
      //   //   this.solutions = resposnse;
      //   //   this.arrangeGrid();
      //   // });
      //   break;
      case 12:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }

        this.saveHistory("genai_pres", 8, this.recordId);
        this.colNumner = 59;
        console.log("This is getTab Data case 1 colnum=59", this.recordId);

        this.thinkingFocusArea = this.genAIThinkingTags[0];
        this.solutionFocusArea = this.genAISolutionTags[0];
        // this.dragWidth =  this.gridList.nativeElement.scrollWidth;
        if (this.genAITabIndex === 0) {
          this.dataService.getAllgenAIThinkingData().subscribe((resposnse) => {
            this.solutions = resposnse;
            console.log("this.solutions", this.solutions);
            this.originalArray = JSON.parse(JSON.stringify(resposnse));
            this.arrangeGrid();
          });
          this.thinkingDragWidth = this.gridList.nativeElement.scrollWidth;
          console.log(this.thinkingDragWidth);
        } else if (this.genAITabIndex === 1) {
          this.dataService.getAllgenAISolutionData().subscribe((resposnse) => {
            this.solutions = resposnse;
            console.log("this.solutions", this.solutions);
            this.originalArray = JSON.parse(JSON.stringify(resposnse));
            this.arrangeGrid();
          });
        }

        break;
      case 4:
        this.colNumner = 5;
        console.log("This is getTab Data case 4 colnum=5");

        // this.dataService.getAllIdeas().subscribe(resposnse => {
        //   this.solutions = resposnse;
        //   this.arrangeGrid();
        // });
        break;
      case 10:
        this.iplsolutiontabClicked = true;
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "160px";
          this.gridWidthOffset = 145;
          this.colNumOffset = 4;
          this.gutterSize = 10;
          this.colNumner = 59;
          console.log("This is getTab Data case 10 colnum=5");
          console.log(this.colNumner, "no");
        } else if (this.screenwidth <= 1400) {
          console.log("1400-tabdata");
          this.gridWidthOffset = 1006;
          this.gutterSize = 6;
          this.gridRowHeight = "110px";
          this.colNumOffset = 20;
          this.colNumner = 59;
          console.log("This is getTab Data case 10 colnum=5");
          console.log(this.colNumner, "no-1366");
        }
        this.saveHistory("ipl_solutions", 8, this.recordId);
        this.dataService.getAllIplSolutions().subscribe((res) => {
          this.iplSolutions = JSON.parse(JSON.stringify(res));
          //console.log("it reaches ipl", this.iplSolutions);

          this.yearWiseIplSolutions = [];

          this.filterIplSolutionsBasedOnyearSelected(
            this.iplSolutions,
            this.iplYear
          );
          //console.log("yearwise", this.yearWiseIplSolutions);
        });
        this.dataService.getAllIplVideos().subscribe((res) => {
          this.iplVideos = JSON.parse(JSON.stringify(res));
          // console.log(this.iplVideos);
          this.YearWiseIplVideos = [];
          this.filterIplLaunchVideoBasedOnYearSelected(
            this.iplVideos,
            this.iplYear
          );

          //console.log("yearwise", this.YearWiseIplVideos);
        });

        //console.log("initial value", this.iplOffsetWidth, this.iplScrollWidth);

        break;
    }
  }

  arrangeGrid() {
    console.log("initial call");
    this.randomizedTileSizesIndex = [];
    this.totCols = 0;
    console.log("This is arrangeGrid totCols=0");
    switch (this.selectedTab) {
      case 11:
        console.log("inside arrange case0");
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "160px";
          this.gridWidthOffset = 145;
          this.colNumOffset = 4;
          this.gutterSize = 10;
        } else if (this.screenwidth <= 1400) {
          console.log("1400-arrange grid");
          this.gridWidthOffset = 1006;
          this.gutterSize = 6;
          this.gridRowHeight = "110px";
          this.colNumOffset = 25;
        }
        for (const element of this.solutions) {
          if (
            element.posterImage.includes("single") ||
            element.catalogId <= 40
          ) {
            this.randomizedTileSizesIndex.push(1);
          } else {
            if (
              element.catalogId == 95 ||
              element.catalogId == 97 ||
              element.catalogId == 99 ||
              element.catalogId == 110
            ) {
              this.randomizedTileSizesIndex.push(1);
            } else {
              this.randomizedTileSizesIndex.push(0);
            }
          }
        }
        this.loading = false;
        break;

      case 1:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }
        for (const element of this.solutions) {
          if (element.type === "video") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "videoExt") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "pdf") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "pdfweb") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "web") {
            this.randomizedTileSizesIndex.push(5);
          }
        }
        this.loading = false;
        //this.renderer.setStyle(this.gridList.nativeElement.children[0], 'height', `5480px`);
        break;
      case 2:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }
        for (const element of this.solutions) {
          if (element.type === "video") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "videoExt") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "pdf") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "pdfweb") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "web") {
            this.randomizedTileSizesIndex.push(5);
          }
        }
        this.loading = false;
        //this.renderer.setStyle(this.gridList.nativeElement.children[0], 'height', `5480px`);
        break;
      case 12:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "200px";
          this.gridWidthOffset = 220;
          this.colNumOffset = 4;
          this.gutterSize = 20;
        } else if (this.screenwidth <= 1400) {
          this.gridWidthOffset = 220;
          this.gutterSize = 10;
          this.gridRowHeight = "145px";
          this.colNumOffset = 4;
        }
        for (const element of this.solutions) {
          if (element.type === "video") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "videoExt") {
            this.randomizedTileSizesIndex.push(3);
          } else if (element.type === "pdf") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "pdfweb") {
            this.randomizedTileSizesIndex.push(4);
          } else if (element.type === "web") {
            this.randomizedTileSizesIndex.push(5);
          }
        }
        this.loading = false;
        //this.renderer.setStyle(this.gridList.nativeElement.children[0], 'height', `5480px`);
        break;
      case 4:
        for (const element of this.solutions) {
          if (element.videoUrl && element.ideaUrl) {
            this.randomizedTileSizesIndex.push(0);
          } else if (element.ideaUrl) {
            this.randomizedTileSizesIndex.push(1);
          } else {
            this.randomizedTileSizesIndex.push(2);
          }
        }
        this.loading = false;
        break;
      case 5:
        for (const element of this.solutions) {
          if (element.videoUrl && element.ideaUrl) {
            this.randomizedTileSizesIndex.push(0);
          } else if (element.ideaUrl) {
            this.randomizedTileSizesIndex.push(1);
          } else {
            this.randomizedTileSizesIndex.push(2);
          }
        }
        this.loading = false;
        break;
      case 10:
        if (this.screenwidth > 1400) {
          this.gridRowHeight = "160px";
          this.gridWidthOffset = 145;
          this.colNumOffset = 4;
          this.gutterSize = 10;
        } else if (this.screenwidth <= 1400) {
          console.log("1400-arrange grid");
          this.gridWidthOffset = 1006;
          this.gutterSize = 6;
          this.gridRowHeight = "110px";
          this.colNumOffset = 20;
        }
        for (const element of this.iplSolutions) {
          if (
            element.posterImage.includes("single") ||
            element.catalogId == 2 ||
            element.catalogId == 4 ||
            element.catalogId == 6 ||
            element.catalogId == 8 ||
            element.catalogId == 10 ||
            element.catalogId == 12 ||
            element.catalogId == 14 ||
            element.catalogId == 16
          ) {
            this.randomizedTileSizesIndex.push(1);
          } else {
            if (
              element.catalogId == 31 ||
              element.catalogId == 95 ||
              element.catalogId == 97 ||
              element.catalogId == 99 ||
              element.catalogId == 11
            ) {
              this.randomizedTileSizesIndex.push(1);
            } else {
              this.randomizedTileSizesIndex.push(0);
            }
          }
        }
        this.loading = false;
        break;
    }
  }

  opneIplInfoPanel(data, index) {
    console.log("hey its a panel");
    this.iplInfoPanel = true;
    this.isInfoPanelVisible = true;
    this.recordId = this.sharedService.getRecordId();
    // this.saveHistory(`open_iplVideos_popover_${this.YearWiseIplVideos[index].category}`, 8, this.recordId, (index + 1));
    this.modalOpen.emit(true);

    if (data.videoUrl.includes("https")) {
      this.videoDomain = "";
      this.infoPanelData = data;
      //  this.infoData = this.infoPanelData.tagName[1];
    } else {
      this.videoDomain = this.sharedService.getDomain();
      this.infoPanelData = data;
    }
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    //const pdfUrl = './assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
    saveAs(pdfUrl, pdfName);
  }

  setUrl() {
    if (this.infoPanelData.dialogData.demo !== "") {
      this.videoPlayer.nativeElement.src = this.infoPanelData.dialogData.demo;
      this.videoPlayer.nativeElement.load();
      // this.videoPlayer.nativeElement.pause();
    }
    if (this.infoPanelData.dialogData.video !== "") {
      this.videoPlayer1.nativeElement.src = this.infoPanelData.dialogData.video;
      this.videoPlayer1.nativeElement.load();
      // this.videoPlayer1.nativeElement.pause();
    }
    if (this.infoPanelData.dialogData.demoESG !== "") {
      this.videoPlayer2.nativeElement.src =
        this.infoPanelData.dialogData.demoESG;
      this.videoPlayer2.nativeElement.load();
      // this.videoPlayer2.nativeElement.pause();
    }
    if (this.infoPanelData.dialogData.demoUnderwriting !== "") {
      this.videoPlayer3.nativeElement.src =
        this.infoPanelData.dialogData.demoUnderwriting;
      this.videoPlayer3.nativeElement.load();
    }
  }
  openInfoPanel(index) {
    console.log("open info panel");
    AppComponent.selectedCardName = this.solutions[index].filename;
    console.log("Title Card:", AppComponent.selectedCardName);
    if (this.solutions[index].ext !== true) {
      if (this.isDraggingStoped) {
        if (this.selectedTab === 11) {
          this.cdr.detectChanges();
          setTimeout(() => this.resetVideo(), 0);
          this.isInfoPanelVisible = true;
          this.recordId = this.sharedService.getRecordId();

          this.saveHistory(
            `open_catalog_popover_${this.solutions[index].catalogName}`,
            8,
            this.recordId,
            index + 1
          );
          this.modalOpen.emit(true);
          if (this.solutions[index].videoUrl.includes("https")) {
            console.log("https if");
            this.videoDomain = "";
            this.infoPanelData = this.solutions[index];
            if (this.infoPanelData.tagName.length <= 2)
              this.infoData = this.infoPanelData.tagName[1];
            else
              this.infoData =
                this.infoPanelData.tagName[1] +
                ", " +
                this.infoPanelData.tagName[2];
            // } else if (this.solutions[index].type === "video") {
            //   console.log("status ", this.stateContactUs);

            //   // if (!this.stateContactUs) {
            //   this.isInfoPanelVisible = true;

            //   this.modalOpen.emit(true);

            //   this.infoPanelData = this.solutions[index];
            //   console.log(this.infoPanelData);

            //   const video = <HTMLVideoElement>(
            //     document.getElementById("singleVideo")
            //   );

            //   if (Hls.isSupported()) {
            //     const hls = new Hls();

            //     hls.loadSource(this.infoPanelData.link);

            //     hls.attachMedia(video);

            //     hls.on(Hls.Events.MANIFEST_PARSED, function () {
            //       // video.play();
            //     });
            //   } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            //     video.src = this.infoPanelData.link;

            //     video.addEventListener("loadedmetadata", function () {
            //       // video.play();
            //     });
            //   }
          } else {
            console.log("https else if");
            this.videoDomain = this.sharedService.getDomain();
            this.infoPanelData = this.solutions[index];
            if (this.infoPanelData.tagName.length <= 2)
              this.infoData = this.infoPanelData.tagName[1];
            else
              this.infoData =
                this.infoPanelData.tagName[1] +
                ", " +
                this.infoPanelData.tagName[2];
          }
          //console.log("info data tab 1", this.infoPanelData);
        } else if (this.selectedTab === 1) {
          this.recordId = this.sharedService.getRecordId();
          this.saveHistory(
            `open_latest_thinking_popover_${this.solutions[index].text}`,
            8,
            this.recordId,
            index + 1
          );
          if (this.solutions[index].type === "video") {
            console.log("status ", this.stateContactUs);

            // if (!this.stateContactUs) {
            this.isInfoPanelVisible = true;

            this.modalOpen.emit(true);

            this.infoPanelData = this.solutions[index];

            const video = <HTMLVideoElement>(
              document.getElementById("singleVideo")
            );

            if (Hls.isSupported()) {
              const hls = new Hls();

              hls.loadSource(this.infoPanelData.link);

              hls.attachMedia(video);

              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                // video.play();
              });
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
              video.src = this.infoPanelData.link;

              video.addEventListener("loadedmetadata", function () {
                // video.play();
              });
            }
            // } else {
            //   this.stateContactUs = false;
            // }
          } else {
            if (this.solutions[index].type !== "pdfweb") {
              window.open(this.solutions[index].link, "_blank");
            }
          }
        } else if (this.selectedTab === 12) {
          this.recordId = this.sharedService.getRecordId();
          this.saveHistory(
            `open_latest_thinking_popover_${this.solutions[index].text}`,
            8,
            this.recordId,
            index + 1
          );
          if (this.solutions[index].type === "video") {
            console.log("status ", this.stateContactUs);

            // if (!this.stateContactUs) {
            this.isInfoPanelVisible = true;

            this.modalOpen.emit(true);

            this.infoPanelData = this.solutions[index];
            console.log(this.infoPanelData);

            const video = <HTMLVideoElement>(
              document.getElementById("singleVideo")
            );

            if (Hls.isSupported()) {
              const hls = new Hls();

              hls.loadSource(this.infoPanelData.link);

              hls.attachMedia(video);

              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                // video.play();
              });
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
              video.src = this.infoPanelData.link;

              video.addEventListener("loadedmetadata", function () {
                // video.play();
              });
            }
            // } else {
            //   this.stateContactUs = false;
            // }
          } else {
            if (this.solutions[index].type !== "pdfweb") {
              window.open(this.solutions[index].link, "_blank");
            }
          }
        } else if (this.selectedTab === 2) {
          this.recordId = this.sharedService.getRecordId();
          this.saveHistory(
            `open_idea_bank_popover_${this.solutions[index].text}`,
            8,
            this.recordId,
            index + 1
          );
          if (this.solutions[index].type === "video") {
            this.isInfoPanelVisible = true;
            this.modalOpen.emit(true);
            this.infoPanelData = this.solutions[index];
            const video = <HTMLVideoElement>(
              document.getElementById("singleVideo")
            );
            if (Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(this.infoPanelData.link);
              hls.attachMedia(video);
              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                // video.play();
              });
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
              video.src = this.infoPanelData.link;
              video.addEventListener("loadedmetadata", function () {
                // video.play();
              });
            }
          } else {
            if (this.solutions[index].type !== "pdfweb") {
              window.open(this.solutions[index].link, "_blank");
            }
          }
        } else if (this.selectedTab === 13) {
          console.log("this.solutions[index]", this.solutions[index]);
          this.recordId = this.sharedService.getRecordId();
          this.saveHistory(
            `open_genAI_popover_${this.solutions[index].text}`,
            8,
            this.recordId,
            index + 1
          );
          if (this.solutions[index].type === "video") {
            console.log("status ", this.stateContactUs);

            // if (!this.stateContactUs) {
            this.isInfoPanelVisible = true;

            this.modalOpen.emit(true);

            this.infoPanelData = this.solutions[index];

            const video = <HTMLVideoElement>(
              document.getElementById("singleVideo")
            );

            if (Hls.isSupported()) {
              const hls = new Hls();

              hls.loadSource(this.infoPanelData.link);

              hls.attachMedia(video);

              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                // video.play();
              });
            } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
              video.src = this.infoPanelData.link;

              video.addEventListener("loadedmetadata", function () {
                // video.play();
              });
            }
            // } else {
            //   this.stateContactUs = false;
            // }
          } else {
            if (this.solutions[index].type !== "pdfweb") {
              window.open(this.solutions[index].link, "_blank");
            }
          }
        }
      }
    } else {
      window.open(this.solutions[index].videoUrl, "_blank");
    }
    setTimeout(() => {
      this.setUrl();
    }, 2000);
  }

  openInfoExt(index) {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(
      `open_catalog_popover_${this.solutions[index].catalogName}`,
      8,
      this.recordId,
      index + 1
    );
  }

  openIdeaPopup(data) {
    this.yammer = false;
    if (this.selectedTab === 2) {
      this.isInfoPanelVisible = true;
      this.infoPanelData = data;
      this.recordId = this.sharedService.getRecordId();
      this.saveHistory(`open_popover_${data.ideaName}`, 8, this.recordId);
      this.modalOpen.emit(true);
    }
  }

  openYammer() {
    this.yammer = true;
    this.isInfoPanelVisible = true;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_yammer`, 8, this.recordId);
    this.infoPanelData = {};
    $("#yammer").html("");
    setTimeout(() => {
      yam.connect.embedFeed({
        network: "cognizant.com",
        feedType: "group",
        feedId: 2508339,
        config: {
          use_sso: false,
          header: true,
          footer: true,
          showOpenGraphPreview: false,
          defaultToCanonical: false,
          hideNetworkName: false,
          defaultGroupId: 2508339,
          theme: "dark",
        },
        container: "#yammer",
      });
    }, 1000);
    this.modalOpen.emit(true);
  }

  openYammerData() {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_yammer`, 8, this.recordId);
  }

  resetVideo() {
    if (this.videoSP && this.videoSP.nativeElement) {
      this.videoSP.nativeElement.load();
      // this.videoSP.nativeElement.play();
    }
    // const video: HTMLVideoElement = this.videoSP.nativeElement;
    // video.pause();
    // video.currentTime = 0;
  }
  closeInfoPanel(data) {
    this.activeTabIndex = 0;
    if (this.video) {
      this.video.nativeElement.pause();
      this.isPlaying = false;
      this.isVideoPlaying = false;
    }
    if (this.videoSP) {
      console.log(this.isVideoPlaying);
      this.videoSP.nativeElement.pause();
      this.isPlaying = false;
      this.isVideoPlaying = false;
    }

    const video = Array.prototype.slice.call(
      document.querySelectorAll(".innerVideos"),
      0
    );
    if (video) {
      video.forEach((ins: HTMLVideoElement) => ins.pause());
    }
    this.isPlaying = false;
    setTimeout(() => {
      this.videoPlayer.nativeElement.pause();
      this.videoPlayer1.nativeElement.pause();
      this.videoPlayer2.nativeElement.pause();
      this.videoPlayer3.nativeElement.pause();
    }, 1000);

    this.isInfoPanelVisible = false;
    this.modalOpen.emit(false);
    this.recordId = this.sharedService.getRecordId();
    this.open_cp = false;
    this.open_bb = false;
    this.open_wm = false;
    this.saveHistory(`close_popover_${data}`, 8, this.recordId);
    this.advice = false;
    this.state = "hide";
  }

  playPause(name = false, url, id) {
    const video = <HTMLVideoElement>document.getElementById("singleVideo");

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = url;
      video.addEventListener("loadedmetadata", function () {
        video.play();
      });
    }
    // if (this.video.nativeElement.paused) {
    //   this.isPlaying = true;
    //   this.video.nativeElement.play();
    // } else {
    //   this.video.nativeElement.pause();
    //   this.isPlaying = false;
    // }
    if (name) {
      this.recordId = this.sharedService.getRecordId();
      this.saveHistory(`play_video_${name}`, 8, this.recordId);

      ga("send", {
        hitType: "event",
        eventCategory: "Videos",
        eventAction: "play",
        eventLabel: name,
      });
    }
    const data = {
      request: {
        userId: this.sharedService.getUserId(),
        mailerFlag: "false",
        timeStamp: Date.now(),
        videoId: id,
      },
    };
    this.dataService.saveVideoInfo(data).subscribe((res) => {});
    if (this.selectedTab == 11) {
      var elem = document.getElementById("singleVideo");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    }
  }

  playAndPause(id) {
    const video = <HTMLVideoElement>(
      document.getElementById("innerVideos_" + id)
    );
    if (video.paused) {
      this.isPlayingCarousel = true;
      video.play();
    } else {
      this.isPlayingCarousel = false;
      video.pause();
    }
  }

  resetFlag() {
    this.isPlayingCarousel = false;
  }
  setPCValue() {
    console.log("This is set property value");
    this.dataService.getAllSolutions().subscribe((resposnse) => {
      this.allSolutions = resposnse;
      this.active = this.allSolutions.filter(
        (s) => s.tagName[s.tagName.length - 1] == "Property & Casualty"
      );
      // this.solutions = this.active;
      if (this.toggleValue == "Active") {
        this.solutions = this.allSolutions.filter(
          (s) =>
            s.tagName[s.tagName.length - 1] == "Property & Casualty" &&
            s.status == "active"
        );
      } else {
        this.solutions = this.allSolutions.filter(
          (s) =>
            s.tagName[s.tagName.length - 1] == "Property & Casualty" &&
            s.status == "in-active"
        );
      }

      this.sols.attributes[0].count = this.solutions.length;
      console.log(
        "setPCValue this.solutions",
        this.solutions.length,
        this.sols.attributes[0].count
      );
      //console.log("hhhhhh", this.sols.attributes[0].count);
    });
  }

  setBenefitsValue() {
    console.log("setBenefitsValue");
    this.dataService.getAllSolutions().subscribe((resposnse) => {
      this.allSolutions = resposnse;
      this.active = this.allSolutions.filter(
        (s) => s.tagName[1] == "Life, Retirement and Group Benefits"
      );
      //this.solutions = this.active;
      if (this.toggleValue == "Active") {
        this.solutions = this.allSolutions.filter(
          (s) =>
            s.tagName[1] == "Life, Retirement and Group Benefits" &&
            s.status == "active"
        );
      } else {
        this.solutions = this.allSolutions.filter(
          (s) =>
            s.tagName[1] == "Life, Retirement and Group Benefits" &&
            s.status == "in-active"
        );
      }
      this.sols.attributes[0].count = this.solutions.length;
      console.log(
        "setBenefitsValue this.solutions",
        this.solutions.length,
        this.sols.attributes[0].count
      );
      //console.log("hhhhhh", this.sols.attributes[0].count);
    });
  }
  setGenAIValue() {
    console.log("setGenAIValue");
    this.dataService.getAllSolutions().subscribe((resposnse) => {
      this.allSolutions = resposnse;
      //this.active = this.allSolutions.filter((s) => s.tagName[1] == "Gen AI");
      // this.solutions = this.active;
      if (this.toggleValue == "Active") {
        this.solutions = this.allSolutions.filter(
          (s) => s.tagName[1] == "Gen AI" && s.status == "active"
        );
      } else {
        this.solutions = this.allSolutions.filter(
          (s) => s.tagName[1] == "Gen AI" && s.status == "in-active"
        );
      }

      this.sols.attributes[0].count = this.solutions.length;
      console.log(
        "setGenAIValue this.solutions",
        this.solutions.length,
        this.sols.attributes[0].count
      );
      //console.log("hhhhhh", this.sols.attributes[0].count);
    });
  }
  setAllValue() {
    this.dataService.getAllSolutions().subscribe((resposnse) => {
      this.allSolutions = resposnse;
      this.active = this.allSolutions.filter((s) => s.tagName[0] == "All");
      //this.solutions = this.active;

      if (this.toggleValue == "Active") {
        this.solutions = this.allSolutions.filter(
          (s) => s.tagName[0] == "All" && s.status == "active"
        );
      } else {
        this.solutions = this.allSolutions.filter(
          (s) => s.tagName[0] == "All" && s.status == "in-active"
        );
      }
      this.sols.attributes[0].count = this.solutions.length;
      console.log("Call setAllValue", this.solutions);
      //console.log("hhhhhh", this.sols.attributes[0].count);
    });
  }
  openModal() {
    console.log(this.downloadPOV);
    console.log("model open");

    this.isOpen = true;
    console.log(this.isOpen);
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory("", 5, this.recordId);
    this.modalOpen.emit(this.isOpen);
    console.log(this.modalOpen);

    this.userId = sessionStorage.getItem("userId");
    this.userName = sessionStorage.getItem("userName");
  }
  closePopup() {
    this.isOpen = false;
    this.alertsuccess = false;

    this.modalOpen.emit(this.isOpen);
    // this.video.nativeElement.pause();
    this.saveHistory(`carousel_close_popover_`, 5, this.recordId);
    this.downloadPOV.reset();
  }
  changeFilter(value) {
    this.tagName = value;
    this.solutions = [];
    this.focusArea = value;
    let twoByTwosCount = 0;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`filter_${value}`, 8, this.recordId);
    if (!value || value.length === 0 || !value) {
      return value;
    }

    value = [value.replace(/_/g, " ")];
    // console.log(value,'value')
    const contains = (s, a) => a.every((w) => s.includes(w));
    const keywordArray = value.map((a) => a.toLowerCase());
    //console.log('keyword array',keywordArray)
    this.randomizedTileSizesIndex = [];
    this.totCols = 0;
    console.log("This is changeFilter totCols=0");

    if (this.selectedTab === 11) {
      this.solutions = this.originalArray.filter((val) => {
        if (
          contains(
            val.tagName.map((res) => res.trim().toLowerCase()),
            keywordArray
          )
        ) {
          return val;
        }
      });
      // this.gridRowHeight = '160px';
      // this.gridWidthOffset = 149;
      // this.colNumOffset = 4;
      // this.gutterSize = 10;
      if (this.screenwidth > 1400) {
        this.gridRowHeight = "160px";
        this.gridWidthOffset = 149;
        this.colNumOffset = 4;
        this.gutterSize = 10;
      } else if (this.screenwidth <= 1400) {
        this.gridWidthOffset = 106;
        this.gutterSize = 6;
        this.gridRowHeight = "110px";
        this.colNumOffset = 4;
      }
      if (this.toggleValue == "Active") {
        this.solutions = this.solutions.filter((s) => s.status == "active");
      } else {
        this.solutions = this.solutions.filter((s) => s.status == "in-active");
      }

      for (const element of this.solutions) {
        if (keywordArray != "all") {
          if (keywordArray == "property & casualty") {
            if (this.toggleValue == "Active") {
              this.totCols = 40;
              this.setPCValue();
              console.log("This is P&C Active totCols");
            } else {
              this.totCols = 5;
              this.setPCValue();
            }
          } else if (keywordArray == "life, retirement and group benefits") {
            if (this.toggleValue == "Active") {
              this.totCols = 40;
              this.setBenefitsValue();
              console.log("This is life,retirement   Active totCols");
            } else {
              this.totCols = 8;
              this.setBenefitsValue();
            }
          } else if (keywordArray == "gen ai") {
            if (this.toggleValue == "Active") {
              this.totCols = 40;
              this.setGenAIValue();
              console.log("This is Gen AI Active totCols");
            } else {
              this.totCols = 11;
              this.setGenAIValue();
            }
          } else {
            this.totCols = 40;
            console.log("This is not P&C and life,retirement totCols");
          }
          if (
            element.catalogId <= 40
            // ||
            // element.catalogId == 49 ||
            // element.catalogId == 45 ||
            // element.catalogId == 26 ||
            // element.catalogId == 29 ||
            // element.catalogId == 46 ||
            // element.catalogId == 44 ||
            // element.catalogId == 43 ||
            // element.catalogId == 101 ||
            // element.catalogId == 45
          ) {
            this.randomizedTileSizesIndex.push(1);
            //twoByTwosCount += 2;
          } else {
            this.randomizedTileSizesIndex.push(0);
          }
        } else {
          if (this.toggleValue == "Active") {
            this.totCols = 25;
            //this.selectTab(11);
            this.setAllValue();
            console.log(
              "This is changeFilter else of if (keywordArray != all) totCols=25"
            );
            if (
              element.posterImage.includes("single") ||
              element.catalogId <= 40
            ) {
              this.randomizedTileSizesIndex.push(1);
              //this.totCols += this.tiles[1].cols;
            } else {
              this.randomizedTileSizesIndex.push(0);
              // this.totCols += this.tiles[0].cols;
            }
          } else {
            // this.colNumner = 15;
            // this.totCols = 15;
            //this.setValue();
            //this.setOtherValue();

            if (
              element.catalogId == 31 ||
              element.catalogId == 95 ||
              element.catalogId == 97 ||
              element.catalogId == 99 ||
              element.catalogId == 110
            ) {
              this.randomizedTileSizesIndex.push(1);
            } else {
              this.randomizedTileSizesIndex.push(0);
            }
          }
        }
      }
    }
    if (this.selectedTab === 1) {
      console.log("this.solutions", this.solutions);
      this.solutions = this.originalArray.filter((val) => {
        if (
          contains(
            val.tags.map((res) => res.trim().toLowerCase()),
            keywordArray
          )
        ) {
          return val;
        }
      });
      if (this.screenwidth > 1400) {
        this.gridRowHeight = "900px";
        this.gridWidthOffset = 220;
        this.colNumOffset = 4;
        this.gutterSize = 20;
      } else if (this.screenwidth <= 1400) {
        this.gridWidthOffset = 170;
        this.gutterSize = 10;
        this.gridRowHeight = "145px";
        this.colNumOffset = 4;
      }
      console.log("this.solutions", this.solutions);
      for (const element of this.solutions) {
        if (element.type === "video" || element.type === "videoExt") {
          this.randomizedTileSizesIndex.push(3);
          this.totCols += this.tiles[3].cols;
        } else if (element.type === "pdf") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "pdfweb") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "web") {
          this.randomizedTileSizesIndex.push(5);
          this.totCols += this.tiles[5].cols;
        }
        /*if(keywordArray != 'all'){
        if (element.type === 'video' || element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'pdf') {
          if(keywordArray == 'sentiment analysis'){
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[0].cols;
          }
          else{
            this.randomizedTileSizesIndex.push(0);
            this.totCols += this.tiles[1].cols;
          }
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        }
      }
      else{
        this.colNumner = 117;
        if (element.type === 'video') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdf') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
        }
      }*/
      }
    }
    if (this.selectedTab === 12 && this.genAITabIndex === 1) {
      console.log("this.solutions", this.solutions);
      this.solutions = this.originalArray.filter((val) => {
        if (
          contains(
            val.tags.map((res) => res.trim().toLowerCase()),
            keywordArray
          )
        ) {
          return val;
        }
      });
      if (this.screenwidth > 1400) {
        this.gridRowHeight = "900px";
        this.gridWidthOffset = 220;
        this.colNumOffset = 4;
        this.gutterSize = 20;
      } else if (this.screenwidth <= 1400) {
        this.gridWidthOffset = 170;
        this.gutterSize = 10;
        this.gridRowHeight = "145px";
        this.colNumOffset = 4;
      }
      console.log("this.solutions", this.solutions);
      for (const element of this.solutions) {
        if (element.type === "video" || element.type === "videoExt") {
          this.randomizedTileSizesIndex.push(3);
          this.totCols += this.tiles[3].cols;
        } else if (element.type === "pdf") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "pdfweb") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "web") {
          this.randomizedTileSizesIndex.push(5);
          this.totCols += this.tiles[5].cols;
        }
        /*if(keywordArray != 'all'){
        if (element.type === 'video' || element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'pdf') {
          if(keywordArray == 'sentiment analysis'){
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[0].cols;
          }
          else{
            this.randomizedTileSizesIndex.push(0);
            this.totCols += this.tiles[1].cols;
          }
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        }
      }
      else{
        this.colNumner = 117;
        if (element.type === 'video') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdf') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
        }
      }*/
      }
    }
    if (this.selectedTab === 2) {
      this.solutions = this.originalArray.filter((val) => {
        if (
          contains(
            val.tags.map((res) => res.trim().toLowerCase()),
            keywordArray
          )
        ) {
          return val;
        }
      });
      if (this.screenwidth > 1400) {
        this.gridRowHeight = "900px";
        this.gridWidthOffset = 220;
        this.colNumOffset = 4;
        this.gutterSize = 20;
      } else if (this.screenwidth <= 1400) {
        this.gridWidthOffset = 170;
        this.gutterSize = 10;
        this.gridRowHeight = "145px";
        this.colNumOffset = 4;
      }

      for (const element of this.solutions) {
        if (element.type === "video" || element.type === "videoExt") {
          this.randomizedTileSizesIndex.push(3);
          this.totCols += this.tiles[3].cols;
        } else if (element.type === "pdf") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "pdfweb") {
          this.randomizedTileSizesIndex.push(4);
          this.totCols += this.tiles[4].cols;
        } else if (element.type === "web") {
          this.randomizedTileSizesIndex.push(5);
          this.totCols += this.tiles[5].cols;
        }
        /*if(keywordArray != 'all'){
        if (element.type === 'video' || element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'pdf') {
          if(keywordArray == 'sentiment analysis'){
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[0].cols;
          }
          else{
            this.randomizedTileSizesIndex.push(0);
            this.totCols += this.tiles[1].cols;
          }
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
          this.totCols += this.tiles[1].cols;
        }
      }
      else{
        this.colNumner = 117;
        if (element.type === 'video') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'videoExt') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdf') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'pdfweb') {
          this.randomizedTileSizesIndex.push(0);
        } else if (element.type === 'web') {
          this.randomizedTileSizesIndex.push(0);
        }
      }*/
      }
    }
    this.colNumner = this.totCols;
    //console.log("This is changeFilter colnum=this.totCols");
    console.log("This is changeFilter colnum=", this.totCols);
    if (this.colNumner < twoByTwosCount) {
      this.colNumner = twoByTwosCount;
      console.log("This is changeFilter colnum=twoByTwosCount");
    }
    if (value[0] === this.filterTags[0]) {
      if (this.toggleValue == "Active") {
        //this.colNumner = 50;
        this.colNumner = this.selectedTab === 11 ? 80 : 59;
        console.log("This is changeFilter (value[0] === this.filterTags[0])");
      } else {
        //this.colNumner = 26;
        this.colNumner = this.selectedTab === 11 ? 24 : 59;
        console.log(
          "This is changeFilter (value[0] === this.filterTags[0]) else "
        );
      }
    }
    this.gridList.nativeElement.scrollLeft = 0;
    this.dragValue = 0;
    this.dragWidth = this.gridList.nativeElement.scrollWidth;
    // if (this.selectedTab === 12 && this.genAITabIndex === 0) {
    //   this.thinkingDragWidth = this.dragWidth;
    //   console.log(this.thinkingDragWidth);
    // } else if (this.selectedTab === 12 && this.genAITabIndex === 1) {
    //   this.dragWidth = this.thinkingDragWidth;
    //   console.log(this.dragWidth);
    // }

    console.log(this.dragWidth, "dw");
    this.renderer.setStyle(
      this.gridList.nativeElement.children[0],
      "width",
      `${this.colNumner * this.gridWidthOffset}px`
    );
    console.log(this.colNumner * this.gridWidthOffset, "calc");
  }

  calclateColnumbers() {
    let totCols = 0;
    console.log("this is calculate numbers totCols=0");
    this.randomizedTileSizesIndex.forEach((element) => {
      totCols += this.tiles[element].cols;
      console.log("this is calculate numbers totCols=0(1)");
    });
    return totCols;
    console.log("this is calculate numbers totCols=0(3)");
  }

  draggingStatus(value) {
    this.isDraggingStoped = value.isEventHappening;
    // console.log(this.isDraggingStoped, 'isDraggingStoped');
    this.dragValue = value.dragvalue;
    this.dragWidth = this.gridList.nativeElement.scrollWidth;
  }

  openCP() {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`view_demo_cp`, 8, this.recordId);
  }

  openBB() {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`view_demo_bb`, 8, this.recordId);
  }

  moreCP() {
    this.open_cp = !this.open_cp;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`read_more_cp`, 8, this.recordId);
    this.getParameterByName("demo", null).subscribe((res) => {
      this.isDemo = res;
    });
  }
  moreBB() {
    this.open_bb = !this.open_bb;
    this.getParameterByName("demo", null).subscribe((res) => {
      this.isDemo = res;
    });

    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`read_more_bb`, 8, this.recordId);
  }

  moreWM() {
    this.open_wm = !this.open_wm;
    this.getParameterByName("demo", null).subscribe((res) => {
      this.isDemo = res;
    });

    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`read_more_wm`, 8, this.recordId);
  }

  moveSection() {
    this.advice = !this.advice;
    this.state = "show";
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_corporate_banking_catalogue`, 8, this.recordId);
  }
  closeExtrapanel() {
    this.state = "hide";
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`close_corporate_banking_catalogue`, 8, this.recordId);
  }

  magnify(imgID, zoom) {
    if (!this.isDemo) {
      return;
    }
    let img, glass, w, h, bw;
    img = document.getElementById(imgID);

    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:
     position: absolute;
                border: 3px solid #000;
                border-radius: 50%;
                cursor: none;
                width: 100px;
                height: 100px;
    */
    img.parentNode.parentNode.style.width = img.width + "px";
    img.parentNode.parentNode.style.margin = "0 auto";
    img.parentNode.parentNode.style.position = "relative";
    const src = img.src.split("/");
    const img_path = `../app/${src[src.length - 2]}/${src[src.length - 1]}`;
    glass.style.backgroundImage = `url(${img_path})`;
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
      img.width * zoom + "px " + img.height * zoom + "px";
    glass.style.width = "150px";
    glass.style.height = "150px";
    // glass.style.border = '3px solid #000';
    glass.style.borderRadius = "50%";
    glass.style.position = "absolute";
    glass.style.backgroundColor = "#FFF";
    // glass.style.setProperty("cursor", "none", "important");
    // img.style.setProperty("cursor", "none", "important");

    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
      let pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);

      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }
      /*set the position of the magnifier glass:*/
      glass.style.left = x - w + "px";
      glass.style.top = y - h + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition =
        "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
    }
    function getCursorPos(e) {
      let a,
        x = 0,
        y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }
  left = 0;
  iplScrlLeft() {
    console.log("scroll left ");

    this.left = this.solutionWrapper.nativeElement.scrollLeft;
    this.disableRightArrow = false;

    this.iplOffsetWidth = this.solutionWrapper.nativeElement.offsetWidth;
    this.iplScrollWidth = this.solutionWrapper.nativeElement.scrollWidth;
    this.solutionWrapper.nativeElement.scrollLeft -= 500;
    if (this.left === 0) {
      this.disableLeftArrow = true;
    } else {
      this.disableLeftArrow = false;
    }
  }
  iplScrlRight() {
    this.left = this.solutionWrapper.nativeElement.scrollLeft;
    this.iplOffsetWidth = this.solutionWrapper.nativeElement.offsetWidth;
    this.iplScrollWidth = this.solutionWrapper.nativeElement.scrollWidth;
    this.solutionWrapper.nativeElement.scrollLeft += 500;
    this.disableLeftArrow = false;
    console.log(this.iplOffsetWidth + this.left);
    console.log(this.iplScrollWidth);
    if (this.iplOffsetWidth + this.left >= this.iplScrollWidth - 1) {
      console.log("we can disable rigt");
      this.disableRightArrow = true;
    } else {
      this.disableRightArrow = false;
    }
  }
  selectedIplYear(year) {
    console.log("year", year);
    this.iplYear = year;
    this.filterIplLaunchVideoBasedOnYearSelected(this.iplVideos, this.iplYear);
    this.filterIplSolutionsBasedOnyearSelected(this.iplSolutions, this.iplYear);
  }
  scrollDivto(year) {
    console.log("solution wrapper", this.solutionWrapper);
    this.left = this.solutionWrapper.nativeElement.scrollLeft;
    this.iplOffsetWidth = this.solutionWrapper.nativeElement.offsetWidth;
    this.iplScrollWidth = this.solutionWrapper.nativeElement.scrollWidth;

    if (year === 2022) {
      console.log("scroll to 2022");
      this.disableLeftArrow = true;
      this.solutionWrapper.nativeElement.scrollLeft = 0;
      if (this.left === 0) {
        this.disableLeftArrow = true;
      } else {
        this.disableLeftArrow = false;
      }
    }
    if (year === 2021) {
      console.log("scrol to 2021");

      this.disableLeftArrow = false;

      console.log(this.disableRightArrow);
      this.solutionWrapper.nativeElement.scrollLeft =
        this.solutionWrapper.nativeElement.offsetWidth + 950;
      if (this.iplOffsetWidth + this.left >= this.iplScrollWidth - 1) {
        console.log("we can disable rigt");
        this.disableRightArrow = false;
      } else {
        this.disableRightArrow = false;
      }
      this.disableRightArrow = false;
    }
    if (year === 2020) {
      console.log("scrol to 2020");

      this.disableLeftArrow = false;

      console.log(this.disableRightArrow);
      this.solutionWrapper.nativeElement.scrollLeft =
        this.solutionWrapper.nativeElement.offsetWidth + 1550;
      if (this.iplOffsetWidth + this.left >= this.iplScrollWidth - 1) {
        console.log("we can disable rigt");
        this.disableRightArrow = true;
      } else {
        this.disableRightArrow = false;
      }
      this.disableRightArrow = true;
    }
  }

  filterIplLaunchVideoBasedOnYearSelected(solutions, year) {
    this.YearWiseIplVideos = [];
    solutions.map((video) => {
      if (video.year === year) {
        this.YearWiseIplVideos.push(video);
      }
    });
    console.log("yearwise", this.YearWiseIplVideos);
  }

  filterIplSolutionsBasedOnyearSelected(solutions, year) {
    this.yearWiseIplSolutions = [];
    solutions.map((sol) => {
      if (sol.year === year) {
        this.yearWiseIplSolutions.push(sol);
      }
    });
    console.log("yearwise", this.yearWiseIplSolutions);
  }
  openJourneyImagePopup() {
    this.modalOpen.emit(true);
    this.iplJourneyImagePopup = !this.iplJourneyImagePopup;
  }
  moveTO(i, title?) {
    //this.stateContactUs = true;
    this.isPlaying = false;

    this.isInfoPanelVisible = false;
    this.modalOpen.emit(false);
    this.selectedIndex = i;

    this.move.emit({ index: i, title });

    //this.closeMenu();
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log("tabChangeEvent => ", tabChangeEvent);
    console.log("index => ", tabChangeEvent.index);
    this.genAITabIndex = tabChangeEvent.index;
    this.getTabData();
    this.sols.attributes[0].value = "Active";
    this.defaultToggle = "Active";
  }

  changeFilter1(value) {
    console.log(value);
    this.tagName = value;
    this.solutions = [];
    this.thinkingFocusArea = value;
    let twoByTwosCount = 0;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`filter_${value}`, 8, this.recordId);
    if (!value || value.length === 0 || !value) {
      return value;
    }

    value = [value.replace(/_/g, " ")];
    // console.log(value,'value')
    const contains = (s, a) => a.every((w) => s.includes(w));
    const keywordArray = value.map((a) => a.toLowerCase());
    //console.log('keyword array',keywordArray)
    this.randomizedTileSizesIndex = [];
    this.totCols = 0;
    console.log("This is changeFilter totCols=0");
    this.solutions = this.originalArray.filter((val) => {
      if (
        contains(
          val.tags.map((res) => res.trim().toLowerCase()),
          keywordArray
        )
      ) {
        return val;
      }
    });
    console.log(this.solutions);
    if (this.screenwidth > 1400) {
      this.gridRowHeight = "900px";
      this.gridWidthOffset = 220;
      this.colNumOffset = 4;
      this.gutterSize = 20;
    } else if (this.screenwidth <= 1400) {
      this.gridWidthOffset = 170;
      this.gutterSize = 10;
      this.gridRowHeight = "145px";
      this.colNumOffset = 4;
    }
    console.log("this.solutions", this.solutions);
    for (const element of this.solutions) {
      if (element.type === "video" || element.type === "videoExt") {
        this.randomizedTileSizesIndex.push(3);
        this.totCols += this.tiles[3].cols;
      } else if (element.type === "pdf") {
        this.randomizedTileSizesIndex.push(4);
        this.totCols += this.tiles[4].cols;
      } else if (element.type === "pdfweb") {
        this.randomizedTileSizesIndex.push(4);
        this.totCols += this.tiles[4].cols;
      } else if (element.type === "web") {
        this.randomizedTileSizesIndex.push(5);
        this.totCols += this.tiles[5].cols;
      }
    }
    console.log(this.randomizedTileSizesIndex);

    this.colNumner = this.totCols;
    //console.log("This is changeFilter colnum=this.totCols");
    console.log("This is changeFilter colnum=", this.totCols);
    if (this.colNumner < twoByTwosCount) {
      this.colNumner = twoByTwosCount;
      console.log("This is changeFilter colnum=twoByTwosCount");
    }
    if (value[0] === this.filterTags[0]) {
      if (this.toggleValue == "Active") {
        //this.colNumner = 50;
        this.colNumner = this.selectedTab === 11 ? 42 : 59;
        console.log("This is changeFilter (value[0] === this.filterTags[0])");
      } else {
        //this.colNumner = 26;
        this.colNumner = this.selectedTab === 11 ? 24 : 59;
        console.log(
          "This is changeFilter (value[0] === this.filterTags[0]) else "
        );
      }
    }
    this.gridList.nativeElement.scrollLeft = 0;
    this.dragValue = 0;
    this.dragWidth = this.gridList.nativeElement.scrollWidth;

    console.log(this.dragWidth, "dw");
    this.renderer.setStyle(
      this.gridList.nativeElement.children[0],
      "width",
      `${this.colNumner * this.gridWidthOffset}px`
    );
    console.log(this.colNumner * this.gridWidthOffset, "calc");
  }
  changeFilter2(value) {
    console.log(value);
    this.tagName = value;
    this.solutions = [];
    this.solutionFocusArea = value;
    let twoByTwosCount = 0;
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`filter_${value}`, 8, this.recordId);
    if (!value || value.length === 0 || !value) {
      return value;
    }

    value = [value.replace(/_/g, " ")];
    // console.log(value,'value')
    const contains = (s, a) => a.every((w) => s.includes(w));
    const keywordArray = value.map((a) => a.toLowerCase());
    //console.log('keyword array',keywordArray)
    this.randomizedTileSizesIndex = [];
    this.totCols = 0;
    console.log("This is changeFilter totCols=0");
    this.solutions = this.originalArray.filter((val) => {
      if (
        contains(
          val.tags.map((res) => res.trim().toLowerCase()),
          keywordArray
        )
      ) {
        return val;
      }
    });
    console.log(this.solutions);
    if (this.screenwidth > 1400) {
      this.gridRowHeight = "900px";
      this.gridWidthOffset = 220;
      this.colNumOffset = 4;
      this.gutterSize = 20;
    } else if (this.screenwidth <= 1400) {
      this.gridWidthOffset = 170;
      this.gutterSize = 10;
      this.gridRowHeight = "145px";
      this.colNumOffset = 4;
    }
    console.log("this.solutions", this.solutions);
    for (const element of this.solutions) {
      if (element.type === "video" || element.type === "videoExt") {
        this.randomizedTileSizesIndex.push(3);
        this.totCols += this.tiles[3].cols;
      } else if (element.type === "pdf") {
        this.randomizedTileSizesIndex.push(4);
        this.totCols += this.tiles[4].cols;
      } else if (element.type === "pdfweb") {
        this.randomizedTileSizesIndex.push(4);
        this.totCols += this.tiles[4].cols;
      } else if (element.type === "web") {
        this.randomizedTileSizesIndex.push(5);
        this.totCols += this.tiles[5].cols;
      }
    }

    this.colNumner = this.totCols;
    //console.log("This is changeFilter colnum=this.totCols");
    console.log("This is changeFilter colnum=", this.totCols);
    if (this.colNumner < twoByTwosCount) {
      this.colNumner = twoByTwosCount;
      console.log("This is changeFilter colnum=twoByTwosCount");
    }
    if (value[0] === this.filterTags[0]) {
      if (this.toggleValue == "Active") {
        //this.colNumner = 50;
        this.colNumner = this.selectedTab === 11 ? 42 : 59;
        console.log("This is changeFilter (value[0] === this.filterTags[0])");
      } else {
        //this.colNumner = 26;
        this.colNumner = this.selectedTab === 11 ? 24 : 59;
        console.log(
          "This is changeFilter (value[0] === this.filterTags[0]) else "
        );
      }
    }
    this.gridList.nativeElement.scrollLeft = 0;
    this.dragValue = 0;
    this.dragWidth = this.gridList.nativeElement.scrollWidth;

    console.log(this.dragWidth, "dw");
    this.renderer.setStyle(
      this.gridList.nativeElement.children[0],
      "width",
      `${this.colNumner * this.gridWidthOffset}px`
    );
    console.log(this.colNumner * this.gridWidthOffset, "calc");
  }
}
