import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedServiceService } from '../services/shared-service.service';
import { DataServiceService } from '../services/data-service.service';
import { ParentComponent } from '../parent/parent.component';
import { AuthWithOAuthService } from '../services/oauth/auth-with-oauth.service';
declare const Hls: any;
// import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  year: number = new Date().getFullYear();
  name:string="";
  password="";
  isHidden:boolean=true;
  val;

  showNavigationArrows = false;
  showNavigationIndicators = false;
  images = [1, 2, 3, 4].map((n) => `../../assets/images/login/slide-image-${n}`);
  constructor(private route: Router, private activatedRoute: ActivatedRoute, public dataService: DataServiceService, private sharedService: SharedServiceService, private authWithOAuth: AuthWithOAuthService) { 
    
  }

  ngOnInit(): void {

    window.open(this.authWithOAuth.getAuthorizeURL(), '_self');

    // const video = <HTMLVideoElement>document.getElementById('labVideo');
    // video.addEventListener('play', () => {
    //   const recordId = this.sharedService.getRecordId();
      
    // });
    // if (Hls.isSupported()) {
    //   const hls = new Hls();
    //   hls.loadSource('https://s3.ap-south-1.amazonaws.com/labvideostream/appvideos/experiencecenter/output/master.m3u8');
    //   hls.attachMedia(video);
    //   hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //     // video.play();
    //   });
    // } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //   video.src = 'https://s3.ap-south-1.amazonaws.com/labvideostream/appvideos/experiencecenter/output/master.m3u8';
    //   video.addEventListener('loadedmetadata', function () {
    //     // video.play();
    //   });
    // }


    // const video = <HTMLVideoElement>document.getElementById('labVideo');
    // video.addEventListener('play', () => {
    //   const recordId = this.sharedService.getRecordId();
      
    // });
    // if (Hls.isSupported()) {
    //   const hls = new Hls();
    //   hls.loadSource('https://labvideostream.s3.ap-south-1.amazonaws.com/appvideos/innovation_Labs_updated_2022/output/master.m3u8');
    //   hls.attachMedia(video);
    //   hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //     // video.play();
    //   });
    // } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    //   video.src = 'https://labvideostream.s3.ap-south-1.amazonaws.com/appvideos/innovation_Labs_updated_2022/output/master.m3u8';
    //   video.addEventListener('loadedmetadata', function () {
    //     // video.play();
    //   });
    // }

    // video.playbackRate=0.75;
    // video.currentTime=7;


    

  }



  submitForm(value:any){
    
       if(value.name=="" || value.password==""){
      alert("Please fill all the fields !");
      
    }else if(value.name.toLowerCase()=="bfsinnovation" && value.password=="bfslab1234"){
      this.route.navigate(['/Home']);
      
     }else if(value.name.toLowerCase()!="bfsinnovation" || value.password!="bfslab1234"){
      alert("Incorrect login credentials");
     }

     this.name='';
     this.password='';
  }

}
