import { DataServiceService } from './../services/data-service.service';
import { ParentComponent } from './../parent/parent.component';
import { OwlCarouselComponent } from './../owl-carousel/owl-carousel.component';
import { SharedServiceService } from './../services/shared-service.service';
import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Input, AfterViewInit, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare var jQuery: any;
@Component({
    selector: 'app-work',
    templateUrl: './work.component.html',
    styleUrls: ['./work.component.scss']
})
@Injectable({
    providedIn: 'root'
})
export class WorkComponent extends ParentComponent implements OnInit, AfterViewInit {
    public activeSlide = 1;
    public isOpen = false;
    public xl_min = '1300px';
    public xxl_min = '1400px';
    public max_width = '1500px';
    public min_width = '1501px';
    public mob_max = '991.98px';
    public build;
    public analysis;
    public implementation;
    public videoDomain;
    public modalData: any;
    public work_src;
    public mySlideOptions = {
        items: 1,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true
    };
    public recordId;

    public suggest = {
        header: 'Suggest',
        image: 'suggest',
        title: 'Where are you placed in your digital journey?',
        list: [
            {
                text: 'Exploring ways to enhance CX/UX'
            },
            {
                text: 'Logical application of Digital technologies in current business environment'
            },
            {
                text: 'Business expansion/ launch plans'
            }
        ],
        footer: [
            {
                text: 'Transform insights (behavioral & data) into working Concepts'
            },
            {
                text: 'Sense making: orchestrate digital tech to re-imagine customer journeys'
            }
        ]
    };

    public elaborate = {
        header: 'Elaborate',
        image: 'elaborate',
        title: 'Where are you placed in your digital journey?',
        list: [
            {
                text: 'Defined go-to-market/ transformation objectives'
            },
            {
                text: 'Evaluating platforms/options for launching solutions'
            },
            {
                text: 'Visualize end solution to define target state features'
            },
            {
                text: 'Human centric design for elaboration of experience'
            }
        ],
        footer: [
            {
                text: 'Visualize concepts as visual working stories'
            },
            {
                text: 'Orchestrate end experience with platforms/ solutions under consideration'
            }
        ]
    };

    public implement = {
        header: 'Implement',
        image: 'implement',
        title: 'Where are you placed in your digital journey?',
        list: [
            {
                text: 'Initiated digital transformation'
            },
            {
                text: 'Over-arching innovation/ re-imagination track to ensure differentiation & agility in design and delivery'
            },
            {
                text: 'Partnership with fintechs for realizing niche features'
            },
            {
                text: 'Tech focused programs aimed at demonstrating value and ROI'
            }
        ],
        footer: [
            {
                text: 'Innovation council – ideation, validation and benchmarking'
            },
            {
                text: 'Business workshops: convert technology capability to business apps'
            }
        ]
    };
    public augment = {
        header: 'Augment',
        image: 'augment',
        title: 'Where are you placed in your digital journey?',
        list: [
            {
                text: 'Ongoing execution – need help with specific technology and functionality gaps'
            },
            {
                text: 'Have new ideas – need validation before implementing them in live projects'
            },
            {
                text: 'Accelerate design, provide users/customers early experience'
            },
            {
                text: 'Gaps in delivered experience'
            }
        ],
        footer: [
            {
                text: 'Digital design & rapid proto-types delivered in Digital Experience Center'
            },
            {
                text: 'Features enrichment or design augmentation through innovation POD teams'
            }
        ]
    };
    public showSugest = false;
    public showElaborate = false;
    public showImplement = false;
    public showAugment = false;
    public overlay = false;
    public data;
    @Input() scroll_move: Subject<any>;
    @Output() modalOpen = new EventEmitter();
    @Output() carouselOpen = new EventEmitter();
    @ViewChild('video') video: ElementRef;
    @ViewChild('owlElement') owlElement: OwlCarouselComponent;
    constructor(public sharedService: SharedServiceService, public dataService: DataServiceService) {
        super(dataService);
    }

    ngOnInit() {
        this.build = {
            title: 'Build',
            videoUrl: 'build.mp4',
            desc: 'The second step in the process being the accurate demonstration of customer journeys, validation of concepts & their prototypes. We start the process by developing wireframes and continuously conduct brainstorming sessions to tackle complexities. Next, we move on to visual design creation, these are edited until finalized as per solution requirements. After these steps are completed, we move on to product development and testing.',
            images: [
                {
                    url: '007.jpg'
                },
                {
                    url: '008.jpg'
                },
                {
                    url: '009.jpg'
                },
                {
                    url: '010.jpg'
                },
                {
                    url: '011.jpg'
                },
                {
                    url: '012.jpg'
                },
                {
                    url: '013.jpg'
                },
                {
                    url: '014.jpg'
                },
                {
                    url: '015.jpg'
                },
                {
                    url: '016.jpg'
                },
                {
                    url: '017.jpg'
                },
                {
                    url: '018.jpg'
                },
                {
                    url: '019.jpg'
                },
                {
                    url: '020.jpg'
                },
                {
                    url: '021.jpg'
                },
                {
                    url: '022.jpg'
                },
                {
                    url: '023.jpg'
                },
                {
                    url: '024.jpg'
                },
                {
                    url: '025.jpg'
                },
                {
                    url: '026.jpg'
                },
                {
                    url: '027.jpg'
                },
                {
                    url: '028.jpg'
                }
            ]
        };
        this.analysis = {
            title: 'Analysis',
            videoUrl: 'analysis.mp4',
            desc: 'Analysis and quick design is the first step in our work cycle, we quickly understand the problem statements received either by our clients or determine them by our own research. Next, we conduct brainstorming sessions to thoroughly understand these problem statements. Following which, the quest for solution begins & basis these brainstorming sessions, we quickly sketch out a design prototype.',
            images: [
                {
                    url: '001.jpg'
                },
                {
                    url: '002.jpg'
                },
                {
                    url: '003.jpg'
                },
                {
                    url: '004.jpg'
                },
                {
                    url: '005.jpg'
                },
                {
                    url: '006.jpg'
                }
            ]
        };
        this.implementation = {
            title: 'Implementation',
            videoUrl: 'implementation.mp4',
            desc: 'Implementation is the last step in the process, after through checking and brainstorming, the MVP is chosen. Next, we move on to the presentation of our solutions to clients or respective internal teams. Showcasing the plethora of potential opportunities we unlock in the Digital Experience Center.',
            images: [
                {
                    url: '029.jpg'
                },
                {
                    url: '030.jpg'
                },
                {
                    url: '031.jpg'
                },
                {
                    url: '032.jpg'
                },
                {
                    url: '033.jpg'
                },
                {
                    url: '034.jpg'
                }
            ]
        };
    }
    ngAfterViewInit() {
        this.scroll_move.pipe(
            debounceTime(1000)
        ).subscribe(res => {
            this.showAugment = false;
            this.showElaborate = false;
            this.showImplement = false;
            this.showSugest = false;
        });
    }
    moveToSlide(i) {
        console.log('movetoslide');
         this.activeSlide = i; 
         console.log(this.activeSlide,'active slide');
         this.carouselOpen.emit(this.activeSlide);
         console.log(this.carouselOpen);
         console.log('called comp2 from 1')
        
    }

    // zoom() {
    //     document.body.style.zoom = "67%" 
    // }
   
    openModal(data) {
        console.log('model open');
        this.modalData = data;
        console.log(this.modalData);
        this.isOpen = true;
        console.log(this.isOpen)
        this.recordId = this.sharedService.getRecordId();
        this.saveHistory(data.title, 5, this.recordId);
        this.modalOpen.emit(this.isOpen);
        console.log(this.modalOpen);
        this.showAugment = false;
        this.showElaborate = false;
        this.showImplement = false;
        this.showSugest = false;
        this.overlay = false;
    }
    closePopup(data) {
        this.isOpen = false;
        this.modalData = null;
        this.modalOpen.emit(this.isOpen);
        // this.video.nativeElement.pause();
        this.saveHistory(`carousel_close_popover_${data}`, 5, this.recordId);
    }

    move(dir) {
        this.owlElement.move(dir);
    }


    openpopover(text) {
        let labelTxt;
        switch (text) {
            case 'suggest':
                this.closePopover(text);
                this.showSugest = true;
                labelTxt = 'engage_expand_suggest';
                this.setLabels(labelTxt);
                break;

            case 'elaborate':
                this.closePopover(text);
                this.showElaborate = true;
                labelTxt = 'engage_expand_elaborate';
                this.setLabels(labelTxt);
                break;

            case 'implement':
                this.closePopover(text);
                this.showImplement = true;
                labelTxt = 'engage_expand_implement';
                this.setLabels(labelTxt);
                break;

            case 'augment':
                this.closePopover(text);
                this.showAugment = true;
                labelTxt = 'engage_expand_augment';
                this.setLabels(labelTxt);
                break;
        }
        this.overlay = true;
    }

    closePopover(type) {
        this.showAugment = false;
        this.showElaborate = false;
        this.showImplement = false;
        this.showSugest = false;
        this.overlay = false;
        let labelTxt;
        if (type === 'suggest') {
            labelTxt = 'close_engage_suggest_popup';
        } else if (type === 'elaborate') {
            labelTxt = 'close_engage_elaborate_popup';
        } else if (type === 'implement') {
            labelTxt = 'close_engage_implement_popup';
        } else if (type === 'augment') {
            labelTxt = 'close_engage_augment_popup';
        }
        this.setLabels(labelTxt);
    }
    setLabels(labelTxt) {
        const recordId = this.sharedService.getRecordId();

        this.saveHistory(labelTxt, 5, recordId);
    }
} 