import { trigger } from '@angular/animations';
import { SharedServiceService } from './../services/shared-service.service';
import { DataServiceService } from './../services/data-service.service';
import { ParentComponent } from './../parent/parent.component';
import { Component, OnDestroy, AfterViewInit, Input, ElementRef, OnInit, OnChanges, ContentChild, ContentChildren, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-owl-carousel',
  templateUrl: './owl-carousel.component.html',
  styleUrls: ['./owl-carousel.component.scss']
})
export class OwlCarouselComponent extends ParentComponent implements OnDestroy, AfterViewInit, OnChanges, OnInit {
  // @HostBinding('class') defaultClass = 'owl-carousel owl-theme';
  @Input() options: object;
  @Input() items;
  @Input() titleText;
  @ContentChildren('innervideo') innervideo;
  @Output() translate = new EventEmitter<number>();
  public item = "01";
  public count;
  public recordId;
  public caption = '';
  $owlElement: any;

  defaultOptions: any = {};

  constructor(private el: ElementRef, public _http: DataServiceService, public sharedService: SharedServiceService) {
    super(_http);
  }
  ngOnInit() {
    this.count = this.items.length;
    

  }
  ngOnChanges() {

  }
  ngAfterViewInit() {

    this.$owlElement = $('.owl-carousel');
    this.$owlElement.on('initialized.owl.carousel', (event) => {
      setTimeout(() => {
        this.caption = this.items[event.item.index].title;
      }, 0);

      this.item = ('0' + (event.item.index + 1)).slice(-2);
    });
    this.$owlElement.owlCarousel(this.options);

    this.$owlElement.on('translated.owl.carousel', (event) => {
      setTimeout(() => {
        this.caption = this.items[event.item.index].title;
      }, 0);
      // this.innervideo.forEach((ins, i) => {
      //   if (i === event.item.index) {
      //     ins.nativeElement.play();
      //   }
      // });
      this.item = ('0' + (event.item.index + 1)).slice(-2);
    });

  }

  ngOnDestroy() {
    this.$owlElement.owlCarousel('destroy');
    this.$owlElement = null;
  }

  move(dir) {
    this.recordId = this.sharedService.getRecordId();
    this.translate.emit();
    this.saveHistory(`carousel_${dir}_${this.titleText}`, 4, this.recordId);
    if (this.innervideo) {
      this.innervideo.forEach(ins => ins.nativeElement.pause());
    }
    switch (dir) {
      case 'left':
        this.$owlElement.trigger('next.owl.carousel');
        break;
      case 'right':
        this.$owlElement.trigger('prev.owl.carousel');
        break;

      default:
        break;
    }
  }
}
