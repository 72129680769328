import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementService {

  constructor() { }
  isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
