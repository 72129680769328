import { DataServiceService } from './../services/data-service.service';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-ideabank',
  templateUrl: './ideabank.component.html',
  styleUrls: ['./ideabank.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdeabankComponent extends ParentComponent implements OnInit {
  @Output() openPopover = new EventEmitter();
  @Input() ideas;
  public recordId;
  constructor(public _http: DataServiceService, public sharedService: SharedServiceService) {
    super(_http);
  }

  ngOnInit() {
  }

  openPopup(data) {
    this.openPopover.emit(data);
    this.recordId = this.sharedService.getRecordId();
    // this.saveHistory(`open_popover_${data.ideaName}`, 5, this.recordId);
  }

}
