import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoaderServiceService } from '../loader/loader-service.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private loaderService: LoaderServiceService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log("inside intercept");
    this.loaderService.startLoader.next(true);

    return next
      .handle(request)
      .pipe(
        catchError((err) => {
          this.loaderService.startLoader.next(false);
          // console.log("inside err");
          return err;
        })
      )
      .pipe(
        map<any, any>((evt) => {
          if (evt instanceof HttpResponse) {
            this.loaderService.startLoader.next(false);
            // console.log("inside map");
          }
          return evt;
        })
      );
  }
}
