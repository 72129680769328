import { DataServiceService } from "./../services/data-service.service";
import { ParentComponent } from "./../parent/parent.component";
import { Component, OnInit } from "@angular/core";
import { SharedServiceService } from "../services/shared-service.service";

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"],
})
export class ContactusComponent extends ParentComponent implements OnInit {
  public topics: any;
  public selectedTopic: any;
  public email;
  public name;
  public recordId;
  constructor(
    public _http: DataServiceService,
    public sharedService: SharedServiceService
  ) {
    super(_http);
  }

  ngOnInit() {
    this.topics = [
      {
        id: "1",
        name: "Artificial Intelligence",
        value: "Artificial Intelligence",
      },
      { id: "2", name: "Machine Learning", value: "Machine Learning" },
      { id: "3", name: "Generative AI", value: "Generative AI" },
      { id: "4", name: "Blockchain", value: "Blockchain" },
      { id: "5", name: "Augmented Reality", value: "Augmented Reality" },
      { id: "6", name: "Virtual Reality", value: "Virtual Reality" },
      { id: "7", name: "P & C", value: "P & C" },
      { id: "8", name: "Life & Annuities", value: "Life & Annuities" },
      { id: "9", name: "Retirement", value: "Retirement" },
      { id: "10", name: "Group Benefits", value: "Group Benefits" },
    ];

    this.selectedTopic = "Artificial Intelligence";
    // document.querySelector('.mat-select-content').parent('div').attr('class', 'nacontentbox');
    console.log("This is twitter log");
  }
  saveHistoryLabels() {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory("contact_us_email", 11, this.recordId);
  }
  enquire(val) {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`enquiry_${val}`, 11, this.recordId);
  }
}
