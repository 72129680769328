import { DataServiceService } from './../services/data-service.service';
import { ParentComponent } from './../parent/parent.component';
import { Component, OnInit } from '@angular/core';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent extends ParentComponent implements OnInit {
  public recordId;
  constructor(public _http: DataServiceService, public sharedService: SharedServiceService) {
    super(_http);
  }

  ngOnInit() {
  }
  opencampaign() {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`campaign_WUThon Innovation Challenge`, 1, this.recordId);
  }
}
