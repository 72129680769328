import { Component, OnInit } from "@angular/core";
import { ParentComponent } from "../parent/parent.component";
import { SharedServiceService } from "../services/shared-service.service";
import { DataServiceService } from "../services/data-service.service";
declare const Hls: any;
@Component({
  selector: "app-video-sreen",
  templateUrl: "./video-sreen.component.html",
  styleUrls: ["./video-sreen.component.scss"],
})
export class VideoSreenComponent extends ParentComponent implements OnInit {
  constructor(
    public dataService: DataServiceService,
    private sharedService: SharedServiceService
  ) {
    super(dataService);
  }

  ngOnInit() {
    const video = <HTMLVideoElement>document.getElementById("labVideo");
    video.addEventListener("play", () => {
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`play_video_our_new_experience_center `, 10, recordId);
    });
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(
        "https://d1xuomfgfakoue.cloudfront.net/FSI_Next_Overview_2022_new_cog_branding/FSI_Next_Overview_2022_new_cog_branding.m3u8"
      );
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        // video.play();
      });
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src =
        "https://d1xuomfgfakoue.cloudfront.net/FSI_Next_Overview_2022_new_cog_branding/FSI_Next_Overview_2022_new_cog_branding.m3u8";
      video.addEventListener("loadedmetadata", function () {
        // video.play();
      });
    }
  }
}
