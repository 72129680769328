import { ParentComponent } from './../parent/parent.component';
import { DataServiceService } from './../services/data-service.service';
import { SharedServiceService } from './../services/shared-service.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pivot',
  templateUrl: './pivot.component.html',
  styleUrls: ['./pivot.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('500ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('500ms', style({ opacity: 0 }))
        ])
      ]
    )
  ],
})
export class PivotComponent extends ParentComponent implements OnInit {
  public max_width = '1500px';
  public min_width = '1501px';
  public mob_max = '767.98px';
  public tab_max = '991.98px';
  // Extra small devices (portrait phones, less than 576px)
  public xs_max = '575.98px';

  // Small devices (landscape phones, 576px and up)
  public sm_min = '576px';
  public sm_max = '767.98px';
  // Medium devices (tablets, 768px and up)
  public md_min = '768px';
  public md_max = '991.98px';

  // Large devices (desktops, 992px and up)
  public lg_min = '992px';
  public lg_max = '1199.98px';


  // Extra large devices (large desktops, 1200px and up)
  public $xl_min = '1200px';

  public engagement;
  public record;
  public inteli;
  public automation;
  public openOverlay;
  public recordId;
  public src;
  public active;
  @Output() modalOpen = new EventEmitter();
  constructor(public sharedService: SharedServiceService, public dataService: DataServiceService) {
    super(dataService);
  }

  ngOnInit() {
    if (window.outerWidth <= 1500) {
      this.src = 'pivot_digital';
    } else if (window.outerWidth >= 1501) {
      this.src = 'pivot_digital_xl';
    }
  }
  openPopover(type) {
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(`open_popover_${type}`, 4, this.recordId);
    switch (type) {
      case 'engagement':
        this.engagement = !this.engagement;
        this.openOverlay = !this.openOverlay;
        this.modalOpen.emit(this.engagement);
        this.active = 'engagement';
        break;
      case 'record':
        this.record = !this.record;
        this.openOverlay = !this.openOverlay;
        this.modalOpen.emit(this.record);
        this.active = 'record';
        break;

      case 'inteli':
        this.inteli = !this.inteli;
        this.openOverlay = !this.openOverlay;
        this.modalOpen.emit(this.inteli);
        this.active = 'inteli';
        break;
      case 'automation':
        this.automation = !this.automation;
        this.openOverlay = !this.openOverlay;
        this.modalOpen.emit(this.automation);
        this.active = 'automation';
        break;

      default:
        break;
    }
  }

  closePopover() {
    this.engagement = false;
    this.openOverlay = false;
    this.record = false;
    this.inteli = false;
    this.automation = false;
    this.modalOpen.emit(false);
    this.saveHistory(`close_popover_${this.active}`, 4, this.recordId);
  }
}
