import { Pipe, PipeTransform } from '@angular/core';
declare const $: any;

@Pipe({
  name: 'multiLineElipsis'
})
export class MultiLineElipsisPipe implements PipeTransform {
  screenwidth: number;
  maxLength: number;
  transform(value: any, args?: any): any {
    this.maxLength = 120;

    this.screenwidth = $(window).width();

    if (this.screenwidth > 700 && this.screenwidth < 800) {
      this.maxLength = 60;
      if (args) {
        this.maxLength = args;
      }
    }


    if (value && value.length > this.maxLength) {
      const str = value.substring(0, this.maxLength) + '...';
      return str;
    } else {
      return value;
    }
  }

}
