import { DataServiceService } from './../services/data-service.service';
import { Component, OnInit } from '@angular/core';
import { Observable, from } from 'rxjs';

@Component({
  selector: 'app-parent',
  template: ``
})
export class ParentComponent implements OnInit {
  constructor(public _http: DataServiceService) { }

  ngOnInit() {

  }

  saveHistory(label_id, screen_id, record_id, tileId?) {
    const data = {
      'request': {
        'recordId': record_id,
        'labelId': `label_${label_id}`,
        'screenId': screen_id,
        "tileId": tileId ? tileId : null
      }
    };

   console.log(`label_${label_id}`)
    this._http.registerJourney(data);
  }

  getParameterByName(name, url): Observable<any> {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) { return from([false]); }
    if (!results[2]) { return from([false]); }
    const value = decodeURIComponent(results[2].replace(/\+/g, ' '));
    return from([value]);
  }

}
