import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-conductahackathon",
  templateUrl: "./conductahackathon.component.html",
  styleUrls: ["./conductahackathon.component.css"],
})
export class ConductahackathonComponent implements OnInit {
  data: any = {};
  name = "";
  email = "";
  formData: any = {};

  alertsuccess: boolean = false;
  alerterror: boolean = false;
  options: any = { year: "numeric", month: "long", day: "numeric" };
  result: any = {};
  responseId = "";
  message = "";
  minDate: any = "";

  @Output() close = new EventEmitter<string>();
  conductahackathon = new FormGroup({
    accounthackathon: new FormControl("", Validators.required),
    cpname: new FormControl("", Validators.required),
    desc: new FormControl("", Validators.required),
    date: new FormControl("", Validators.required),
    stakeholder: new FormControl("", Validators.required),
  });
  constructor(private http: HttpClient) {}
  ngOnInit() {
    this.disablePastDate();
  }

  disablePastDate() {
    var currentDate: any = new Date();
    var toDate: any = currentDate.getDate();
    if (toDate < 10) {
      toDate = "0" + toDate;
    }
    var currentmonth: any = currentDate.getMonth() + 1;
    if (currentmonth < 10) {
      currentmonth = "0" + currentmonth;
    }
    var currentyear = currentDate.getFullYear();
    currentDate = currentyear + "-" + currentmonth + "-" + toDate;
    this.minDate = currentDate;
    console.log("Current Date", this.minDate);
  }

  onSubmit() {
    var formatdate = new Date(
      this.conductahackathon.get("date").value
    ).toLocaleDateString("en-us", this.options);

    this.formData = {
      formName: "ConductHackathon",
      userAccount: this.conductahackathon.get("accounthackathon").value,
      usercp: this.conductahackathon.get("cpname").value,
      userdesc: this.conductahackathon.get("desc").value,
      userdate: formatdate,
      userStakeholder: this.conductahackathon.get("stakeholder").value,
      username: sessionStorage.getItem("userName"),
      userEmployeeId: sessionStorage.getItem("userId"),
    };
    if (this.conductahackathon.valid) {
      console.log("form submitted");
      this.http
        .post(
          "https://gag6jl5kei.execute-api.us-east-1.amazonaws.com/prod/",
          this.formData
        )
        .subscribe((response) => {
          this.result = response;
          this.responseId = JSON.stringify(this.result.MessageId);
          if (!this.responseId) {
            this.alerterror = true;
            this.closeAlertSuccess();
            this.message = "Something went wrong,Please try again later";
          } else {
            this.alertsuccess = true;
            this.message =
              "Form Submitted Successfully! We will contact you shortly.";
            this.closeAlertError();
          }
        });
      setTimeout(() => {
        this.close.emit();
      }, 4000);
    } else {
      // validate all form fields
      console.log("form not valid");
    }
  }
  closeAlertSuccess() {
    this.alertsuccess = false;
  }
  closeAlertError() {
    this.alerterror = false;
  }
}
