import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattags'
})
export class FormattagsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const arr = value.map(x => x.trim());
      const str = arr.join(' #');
      return str;
    }
    return null;
  }

}
