import { Component, OnInit } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { DataServiceService } from '../services/data-service.service';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-gartner',
  templateUrl: './gartner.component.html',
  styleUrls: ['./gartner.component.scss']
})
export class GartnerComponent extends ParentComponent implements OnInit {

  constructor(private dataService: DataServiceService, private sharedService: SharedServiceService) {
    super(dataService);
   }

  boxLayoutData = [
    {
      heading: 'Digital first, why should it be any other way',
      descHeading: 'My phone is my bank',
      descPoints: [
        'All simple financial needs delivered anytime anywhere, don’t care if it’s an actual bank',
        'Custom advice for complex needs delivered preferably through digital'
      ]

    },
    {
      heading: 'Digital access is must have, high-touch advice only for very complex needs',
      descHeading: 'My bank designed around my fluid & phygital life',
      descPoints: [
        'Available where I am',
        'Helps me manage what I have no time for',
        'Acknowledges my success & helps me grow',
        'Helps manage my inherited wealth'
      ]
    },
    {
      heading: 'Happy to receive better advice/service through digitally augmented & traditional channels',
      descHeading: 'Good to have a branch nearby, but don’t recall when last visited',
      descPoints: [
        'Do both digital & cash payments',
        'Can try any new service once',
        'Proximity to my bank required mostly for exceptions'
      ]
    },
    {
      heading: 'Might try digital but only for simple financial needs',
      descHeading: 'Digital banking is for new generation',
      descPoints: [
        'Not comfortable with digital technologies',
        'It’s error-prone & don’t think it’s secure when significant money is involved',
        'Someone booked an UBER for me, wasn’t bad'
      ]
    },
    {
      heading: 'Deliver good old services, happy if it’s faster',
      descHeading: 'My bank still has a face but is more efficient & accurate',
      descPoints: [
        'Please do it for me',
        'My advisor knows finance better than me & is well informed',
        'Appreciate if it takes less effort & is done faster',
        'Digital access isn’t all bad but can’t bank on it for all important things'
      ]
    }
  ];
  boxLayoutDataRight = [
    {
      heading: 'Help expand reach through insights & finance',
      descHeading: 'Help me connect to business ecosystem, make services available anytime anywhere'
    },
    {
      heading: 'Traditional solutions don’t work when trading in digital assets through digital resources',
      descHeading: 'Customized products & advisory for digital businesses'
    },
    {
      heading: 'Optimize cost of running my business',
      descHeading: 'Value based advisory through digital platforms'
    },
    {
      heading: 'Industry/business customized working capital solutions – rapid on-demand processing',
      descHeading: 'Provide me intelligent solutions: insights, connected marketplaces, on-demand services'
    },
    {
      heading: 'Access to working capital finance most critical',
      descHeading: 'Assist me grow the business'
    },
    {
      heading: 'Integrate with insights in my ERP, GLocal working capital services – seamless self-service',
      descHeading: 'Solve my perennial challenges like supply chain, working capital, integrated banking & payments'
    }
  ];
  boxSelectedLeft = false;
  boxSelectedLeftId = -1;
  boxSelectedRight = false;
  boxSelectedRightId = -1;
  selectedHeading;
  headingPoints;
  selectedescdHeading;

  ngOnInit() {
  }

  closePopOver(e , side, id) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.boxSelectedLeftId = -1;
    this.boxSelectedRightId = -1;
    this.showBoxLayout();
    this.showBoxLayoutRight();
    this.setCloseLabels(side, id);
  }
  selectBoxLeft(id) {
    this.boxSelectedLeft = true;
    this.boxSelectedLeftId = id;
    this.selectedHeading = this.boxLayoutData[id].heading;
    this.selectedescdHeading = this.boxLayoutData[id].descHeading;
    this.headingPoints = this.boxLayoutData[id].descPoints;
    this.setLabels('left', id);

  }

  setLabels(side, id) {
    let labelTxt;
    if (side === 'left') {
      if (id === 0) {
        labelTxt = 'digital_first_why_should_it_be_any_other_way';
      } else if (id === 1) {
        labelTxt = 'digital_access_is_must_have_hightouch_advice_only_for_very_complex_needs';
      } else if (id === 2) {
        labelTxt = 'happy_to_receive_better_advice_through_digitally_augmented_traditional_channels';
      } else if (id === 3) {
        labelTxt = 'might_try_digital_but_only_for_simple_financial_needs';
      } else if (id === 4) {
        labelTxt = 'deliver_good_old_services_happy_if_its_faster';
      }
    } else if (side === 'right') {
      if (id === 0) {
        labelTxt = 'help_expand_reach_through_insights_&_finance';
      } else if (id === 1) {
        labelTxt = 'traditional_solutions_dont_work_when_trading_in_digital_assets_through_digital_resources';
      } else if (id === 2) {
        labelTxt = 'optimize_cost_of_running_my_business';
      } else if (id === 3) {
        labelTxt = 'industry_customized_working_capital_solutions_rapid_ondemand_processing';
      } else if (id === 4) {
        labelTxt = 'access_to_working_capital_finance_most_critical';
      } else if (id === 5) {
        labelTxt = 'integrate_with_insights_in_my_ERP_GLocal_working_capital_services_seamless_self_service';
      }
    }

    const recordId = this.sharedService.getRecordId();
    console.log(labelTxt , 'labelTxt', recordId, 'record id');

    this.saveHistory(labelTxt, 2, recordId);

  }
  setCloseLabels(side, id) {
    let labelTxt;
    if (side === 'left') {
      if (id === 0) {
        labelTxt = 'cross_my_phone_is_my_bank';
      } else if (id === 1) {
        labelTxt = 'cross_my_bank_designed_around_my_fluid_&_phygital_life';
      } else if (id === 2) {
        labelTxt = 'cross_good_to_have_a_branch_nearby_but_dont_recall_when_last_visited';
      } else if (id === 3) {
        labelTxt = 'cross_digital_banking_is_for_new_generation';
      } else if (id === 4) {
        labelTxt = 'cross_my_bank_still_has_a_face_but_is_more_efficient_&_accurate';
      }
    } else if (side === 'right') {
      if (id === 0) {
        labelTxt = 'cross_help_me_connect_to_business_ecosystem_make_services_available_anytime_anywhere';
      } else if (id === 1) {
        labelTxt = 'cross_customized_products_&_advisory_for_digital_businesses';
      } else if (id === 2) {
        labelTxt = 'cross_value_based_advisory_through_digital_platforms';
      } else if (id === 3) {
        labelTxt = 'cross_provide_me_intelligent_solutions_insights_connected_marketplaces_on_demand_services';
      } else if (id === 4) {
        labelTxt = 'cross_assist_me_grow_the_business';
      } else if (id === 5) {
        labelTxt = 'cross_solve_my_perennial_challenges_like_supply_chain_working_capital_integrated_banking_&_payments';
      }
    }
    const recordId = this.sharedService.getRecordId();
    console.log(labelTxt , 'labelTxt', recordId, 'record id');

    this.saveHistory(labelTxt, 2, recordId);

  }

  selectBoxRight(id) {
    this.boxSelectedRightId = id;
    this.boxSelectedRight = true;
    this.selectedHeading = this.boxLayoutDataRight[id].heading;
    this.selectedescdHeading = this.boxLayoutDataRight[id].descHeading;
    this.headingPoints = [];
    this.setLabels('right', id);
  }

  showBoxLayout() {
    this.boxSelectedLeft = false;
  }
  showBoxLayoutRight() {
    this.boxSelectedRight = false;
  }

}
