import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
} from "@angular/core";
import { fromEvent, empty, Subject } from "rxjs";
import {
  map,
  concatMap,
  takeUntil,
  elementAt,
  catchError,
  merge,
  debounceTime,
} from "rxjs/operators";
import { DataServiceService } from "../services/data-service.service";
import { ParentComponent } from "../parent/parent.component";
import { SharedServiceService } from "../services/shared-service.service";

@Component({
  selector: "app-theloft",
  templateUrl: "./theloft.component.html",
  styleUrls: ["./theloft.component.scss"],
})
export class TheloftComponent
  extends ParentComponent
  implements OnInit, AfterViewInit
{
  @ViewChild("swipeMe") swipeMe: ElementRef;
  @Input() scrolled: Subject<any>;
  @Output() move = new EventEmitter<any>();
  @Output() modalOpen = new EventEmitter();
  public activeSlide = 0;
  public max_width = "1500px";
  public min_width = "1501px";
  isVideoPanelVisible: boolean;
  public isOpen = false;
  public recordId;
  constructor(
    public dataService: DataServiceService,
    public sharedService: SharedServiceService
  ) {
    super(dataService);
  }

  ngOnInit() {
    this.isVideoPanelVisible = false;
    const mouseEventToCoordinate = (mouseEvent) => {
      return {
        x: mouseEvent.clientX,
        y: mouseEvent.clientY,
        ev: mouseEvent,
      };
    };

    const touchEventToCoordinate = (touchEvent) => {
      return {
        x: touchEvent.changedTouches[0].clientX,
        y: touchEvent.changedTouches[0].clientY,
        ev: touchEvent,
      };
    };

    const mouseDowns = fromEvent(this.swipeMe.nativeElement, "mousedown").pipe(
      map(mouseEventToCoordinate)
    );

    const mouseMoves = fromEvent(this.swipeMe.nativeElement, "mousemove").pipe(
      map(mouseEventToCoordinate)
    );
    const mouseUps = fromEvent(this.swipeMe.nativeElement, "mouseup").pipe(
      map(mouseEventToCoordinate)
    );
    const touchStarts = fromEvent(
      this.swipeMe.nativeElement,
      "touchstart"
    ).pipe(map(touchEventToCoordinate));
    const touchMoves = fromEvent(this.swipeMe.nativeElement, "touchmove").pipe(
      map(touchEventToCoordinate)
    );
    const touchEnds = fromEvent(this.swipeMe.nativeElement, "touchend").pipe(
      map(touchEventToCoordinate)
    );

    const starts = mouseDowns.pipe(merge(touchStarts));
    const moves = mouseMoves.pipe(merge(touchMoves));
    const ends = mouseUps.pipe(merge(touchEnds));

    starts
      .pipe(
        concatMap((dragStartEvent) => {
          return moves.pipe(
            takeUntil(ends),
            elementAt(3),
            catchError((err) => empty()),
            map((ev) => {
              const intialDeltaX = ev.x - dragStartEvent.x;
              const initialDeltaY = ev.y - dragStartEvent.y;
              return {
                x: dragStartEvent.x,
                y: dragStartEvent.y,
                intialDeltaX,
                initialDeltaY,
                event: ev.ev,
              };
            })
          );
        })
      )
      .subscribe((res) => {
        if (Math.abs(res.initialDeltaY) < Math.abs(res.intialDeltaX)) {
          /*most significant*/
          if (res.intialDeltaX > 0) {
            this.swipe(0);
          } else {
            /* right swipe */
            this.swipe(1);
          }
        }
      });
  }

  ngAfterViewInit() {
    this.scrolled.pipe(debounceTime(1000)).subscribe((res) => this.swipe(0));
  }

  swipe(i) {
    const video = <HTMLVideoElement>document.getElementById("labVideo");
    if (video) {
      video.pause();
    }
    const carousel_container = document.querySelector(".carousel__wrapper");
    const left = i * document.body.scrollWidth;
    this.activeSlide = i;
    carousel_container["style"].left = `-${left}px`;
    const recordId = this.sharedService.getRecordId();
    this.saveHistory(i === 0 ? "swipe_left" : "swipe_right", 3, recordId);
  }

  openVideoPanel() {
    this.isVideoPanelVisible = true;
  }

  closeVideoPanel() {
    this.isVideoPanelVisible = false;
  }
  moveTOHackathon(i, title?) {
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }
  moveTOPartner(i, title?) {
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }
  moveTOPov(i, title?) {
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }
  moveTOEmergTech(i, title?) {
    console.log(title);
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }
  moveTOInnovation(i, title?) {
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }
  moveTOSuccessStories(i, title?) {
    this.modalOpen.emit(false);
    this.move.emit({ index: i, title });
  }

  openModal() {
    console.log("model open");
    this.isOpen = true;
    console.log(this.isOpen);
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory("", 5, this.recordId);
    this.modalOpen.emit(this.isOpen);
    console.log(this.modalOpen);
  }
  closePopup() {
    this.isOpen = false;

    this.modalOpen.emit(this.isOpen);
    // this.video.nativeElement.pause();
    this.saveHistory(`carousel_close_popover_`, 5, this.recordId);
  }
}
