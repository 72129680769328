import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { tap, map, merge, concatMap, takeUntil} from 'rxjs/operators';
import { fromEvent} from 'rxjs';
declare var $: any;
@Directive({
  selector: '[appHorizontalDrag]'
})
export class HorizontalDragDirective implements OnInit {
  mouseDown: boolean;
  private last: any;
  dragVAlue: number;
  starts: any;
  moves: any;
  ends: any;
  @Input() appHorizontalDrag: any;
  @Output() dragEvent = new EventEmitter<any>();



  constructor(private el: ElementRef<any>) {
    this.mouseDown = false;
    const mouseEventToCoordinate = mouseEvent => {
      // mouseEvent.preventDefault();
      return {
        x: mouseEvent.clientX,
        y: mouseEvent.clientY
      };
    };

    const touchEventToCoordinate = touchEvent => {
      // touchEvent.preventDefault();
      return {
        x: touchEvent.changedTouches[0].clientX,
        y: touchEvent.changedTouches[0].clientY
      };
    };
    const mouseDowns = fromEvent(this.el.nativeElement, 'mousedown').pipe(
      map(mouseEventToCoordinate)
    );

    const mouseMoves = fromEvent(this.el.nativeElement, 'mousemove').pipe(
      map(mouseEventToCoordinate)
    );
    const mouseUps = fromEvent(this.el.nativeElement, 'mouseup').pipe(
      map(mouseEventToCoordinate)
    );

    const touchStarts = fromEvent(this.el.nativeElement, 'touchstart').pipe(
      map(touchEventToCoordinate)
    );
    const touchMoves = fromEvent(this.el.nativeElement, 'touchmove').pipe(
      map(touchEventToCoordinate)
    );
    const touchEnds = fromEvent(this.el.nativeElement, 'touchend').pipe(
      map(touchEventToCoordinate)
    );
    this.starts = mouseDowns.pipe(
      merge(touchStarts)
    );
    this.moves = mouseMoves.pipe(
      merge(touchMoves)
    );
    this.ends = mouseUps.pipe(
      merge(touchEnds),
      tap(event => {
        this.mouseDown = false;
        setTimeout(() => {
          this.dragEvent.emit({
            isEventHappening: true,
            dragvalue: this.dragVAlue
          });
        }, 100);
      })
    );
  }

  ngOnInit() {
    this.starts.pipe(
      tap(event => {
        this.last = event;
        this.mouseDown = true;
      }),
      concatMap((dragStartEvent) => {
        return this.moves.pipe(
          takeUntil(this.ends)
        );
      })
    ).subscribe(event => {
      if (this.mouseDown) {
        if (event.x < this.last.x) {
          this.el.nativeElement.scrollLeft = this.el.nativeElement.scrollLeft + (this.last.x - event.x);
          this.dragVAlue = this.el.nativeElement.scrollLeft + $(this.el.nativeElement).innerWidth();
          this.dragEvent.emit({
            isEventHappening: false,
            dragvalue: this.dragVAlue
          });
        } else if (event.x > this.last.x) {
          this.el.nativeElement.scrollLeft = this.el.nativeElement.scrollLeft - (event.x - this.last.x);
          this.dragVAlue = this.el.nativeElement.scrollLeft;
          this.dragEvent.emit({
            isEventHappening: false,
            dragvalue: this.dragVAlue
          });
        }
        this.last = event;
      }
    });
  }


  @HostListener('wheel', ['$event'])
  onwheel(event: WheelEvent) {
    if (event.wheelDeltaY >= -100 && event.wheelDeltaY <= 100) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }

  }


}
