import { Component, OnInit } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { DataServiceService } from '../services/data-service.service';
import { SharedServiceService } from '../services/shared-service.service';
@Component({
  selector: 'app-digital-scale-transformation',
  templateUrl: './digital-scale-transformation.component.html',
  styleUrls: ['./digital-scale-transformation.component.scss']
})
export class DigitalScaleTransformationComponent extends ParentComponent implements OnInit {
  content = false;
  dynamicClasses = {
    tile1: '',
    tile2: '',
    tile3: '',
    tile4: '',
    tile5: '',
    tile6: '',
    tile7: '',
    tile8: '',
    tile9: ''
  };
  dynamicFlipClasses = {
    flip1: false,
    flip2: false,
    flip3: false,
    flip4: false,
    flip5: false,
    flip6: false,
    flip7: false,
    flip8: false,
    flip9: false
  };
  showRetainTheBusinessJourney: boolean;
  showGrowTheBusiness: boolean;
  showDisruptTheMarket: boolean;
  contentFlipData = [
    'Digital Engineering',
    'Interactive',
    'Intelligence Process Automation',
    'Industry & Platform Solutions',
    'Analytics & AI',
    'Core Modernization',
  ];

  tranformationPathData = [
    { title: 'Core re-platforming driven', desc: 'Re-platforming core systems, re-engineering processes while improving customer experience and driving cost optimization' },
    { title: 'Agility in market/ Customer response driven', desc: 'Define target experiences & digital delivery model and re-architect processes and architecture to enable the experience' },
    { title: 'Channel/ experience driven', desc: 'Accelerate digital experience through human centric experience design, re-imagining processes and adjusting the core to support' },
    { title: 'Cost of operations driven', desc: 'Automate processes & remediate IT to address cost/quality' },
    { title: 'Digital entry/ growth driven', desc: '' }

  ];

  activeDescriptions = [];

  constructor(private dataService: DataServiceService, private sharedService: SharedServiceService) {
    super(dataService);
   }

  ngOnInit() {
    this.showRetainTheBusinessJourney = false;
  }
  resetClasses() {
    this.dynamicClasses = {
      tile1: '',
      tile2: '',
      tile3: '',
      tile4: '',
      tile5: '',
      tile6: '',
      tile7: '',
      tile8: '',
      tile9: ''
    };
    this.activeDescriptions = [];
    // this.showDisruptTheMarket = this.showGrowTheBusiness = this.showRetainTheBusinessJourney = false;
  }

  showRetainTheBusinessJourneyMethod() {
    if (!this.content) {
      this.resetClasses();
      this.showDisruptTheMarket = false;
      this.showGrowTheBusiness = false;
      this.showRetainTheBusinessJourney = !this.showRetainTheBusinessJourney;
      if (this.showRetainTheBusinessJourney) {

        this.dynamicClasses.tile1 = 'set-applied set1_1';
        this.dynamicClasses.tile4 = 'set-applied set1_1';
        this.dynamicClasses.tile5 = 'set-applied set1_2';
        this.dynamicClasses.tile6 = 'set-applied set1_3';
        this.dynamicClasses.tile7 = 'set-applied set1_1';
        this.dynamicClasses.tile8 = 'set-applied set1_2';
        this.dynamicClasses.tile9 = 'set-applied set1_3';
        this.activeDescriptions = [0, 2, 3];
      }
      this.setLabels('digital_transforamtion_retain_the_business');
    }
  }
  showGrowTheBusinessMethod() {
    if (!this.content) {

      this.resetClasses();
      this.showRetainTheBusinessJourney = false;
      this.showDisruptTheMarket = false;
      this.showGrowTheBusiness = !this.showGrowTheBusiness;
      if (this.showGrowTheBusiness) {

        this.dynamicClasses.tile2 = 'set-applied set2_1';
        this.dynamicClasses.tile5 = 'set-applied set2_1';
        this.dynamicClasses.tile6 = 'set-applied set2_2';
        this.dynamicClasses.tile8 = 'set-applied set2_1';
        this.dynamicClasses.tile9 = 'set-applied set2_2';
        this.activeDescriptions = [1];
      }
      this.setLabels('digital_transforamtion_grow_the_business');
    }
  }
  showDisruptTheMarketMethod() {
    if (!this.content) {
      this.resetClasses();
      this.showGrowTheBusiness = false;
      this.showRetainTheBusinessJourney = false;
      this.showDisruptTheMarket = !this.showDisruptTheMarket;
      if (this.showDisruptTheMarket) {

        this.dynamicClasses.tile3 = 'set-applied set3_1';
        this.dynamicClasses.tile6 = 'set-applied set3_1';
        this.dynamicClasses.tile9 = 'set-applied set3_1';
        this.activeDescriptions = [4];
      }
      this.setLabels('digital_transforamtion_disrupt_the_market');

    }
  }

  flipTile() {
    this.content = !this.content;
    if (this.content) {
      this.dynamicFlipClasses.flip1 = true;
      this.dynamicFlipClasses.flip6 = true;
      this.dynamicFlipClasses.flip8 = true;
      setTimeout(() => {
        this.dynamicFlipClasses.flip2 = true;
        this.dynamicFlipClasses.flip3 = true;
        this.dynamicFlipClasses.flip4 = true;
        this.dynamicFlipClasses.flip9 = true;
        setTimeout(() => {
          this.dynamicFlipClasses.flip7 = true;
          this.dynamicFlipClasses.flip5 = true;
        }, 500);
      }, 500);
    } else {
      this.dynamicFlipClasses = {
        flip1: false,
        flip2: false,
        flip3: false,
        flip4: false,
        flip5: false,
        flip6: false,
        flip7: false,
        flip8: false,
        flip9: false
      };
    }
    this.setLabels('digital_transforamtion_toggle');
  }
  setLabels(labelTxt) {
    const recordId = this.sharedService.getRecordId();
    console.log(labelTxt , 'labelTxt', recordId, 'record id');

    this.saveHistory(labelTxt, 4, recordId);
  }
}
