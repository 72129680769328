import { Component, OnInit } from '@angular/core';
import { ParentComponent } from '../parent/parent.component';
import { DataServiceService } from '../services/data-service.service';
import { SharedServiceService } from '../services/shared-service.service';

@Component({
  selector: 'app-howcanwehelp',
  templateUrl: './howcanwehelp.component.html',
  styleUrls: ['./howcanwehelp.component.scss']
})
export class HowcanwehelpComponent extends ParentComponent implements OnInit {

  public suggest = {
    header: 'Suggest',
    image: 'suggest',
    title: 'Where are you placed in your digital journey?',
    list: [
      {
        text: 'Exploring ways to enhance CX/UX'
      },
      {
        text: 'Logical application of Digital technologies in current business environment'
      },
      {
        text: 'Business expansion/ launch plans'
      }
    ],
    footer: [
      {
        text: 'Transform insights (behavioral & data) into working Concepts'
      },
      {
        text: 'Sense making: orchestrate digital tech to re-imagine customer journeys'
      }
    ]
  };

  public elaborate = {
    header: 'Elaborate',
    image: 'elaborate',
    title: 'Where are you placed in your digital journey?',
    list: [
      {
        text: 'Defined go-to-market/ transformation objectives'
      },
      {
        text: 'Evaluating platforms/ options to bring solutions to life'
      },
      {
        text: 'Visualize end solution to define target state features'
      },
      {
        text: 'Human centric design for elaboration of experience'
      }
    ],
    footer: [
      {
        text: 'Visualize concepts as visual working stories'
      },
      {
        text: 'Orchestrate end experience with platforms/ solutions under consideration'
      }
    ]
  };

  public implement = {
    header: 'Implement',
    image: 'implement',
    title: 'Where are you placed in your digital journey?',
    list: [
      {
        text: 'Initiated digital transformation'
      },
      {
        text: 'Over-arching innovation/ re-imagination track to ensure differentiation & agility in design and delivery'
      },
      {
        text: 'Partnership with fintechs for realizing niche features'
      },
      {
        text: 'Tech focused programs aimed at demonstrating value and ROI'
      }
    ],
    footer: [
      {
        text: 'Innovation council – ideation, validation and benchmarking'
      },
      {
        text: 'Business workshops: convert technology capability to business apps'
      }
    ]
  };
  public augment = {
    header: 'Augment',
    image: 'augment',
    title: 'Where are you placed in your digital journey?',
    list: [
      {
        text: 'Ongoing execution – need help with specific technology and functionality gaps'
      },
      {
        text: 'Have new ideas – need validation before implementing them in live projects'
      },
      {
        text: 'Accelerate design, provide users/customers early experience'
      },
      {
        text: 'Gaps in delivered experience'
      }
    ],
    footer: [
      {
        text: 'Digital design & rapid proto-types delivered in Digital Experience Center'
      },
      {
        text: 'Features enrichment or design augmentation through innovation POD teams'
      }
    ]
  };
  public showSugest = false;
  public showElaborate = false;
  public showImplement = false;
  public showAugment = false;
  public overlay = false;
  public data;

  constructor(private dataService: DataServiceService, private sharedService: SharedServiceService) {
    super(dataService);
   }
  ngOnInit() {
  }
  openpopover(text) {
    let labelTxt;
    switch (text) {
      case 'suggest':
        this.closePopover(text);
        this.showSugest = true;
        labelTxt = 'engage_expand_suggest';
        this.setLabels(labelTxt);
        break;

      case 'elaborate':
        this.closePopover(text);
        this.showElaborate = true;
        labelTxt = 'engage_expand_elaborate';
        this.setLabels(labelTxt);
        break;

      case 'implement':
        this.closePopover(text);
        this.showImplement = true;
        labelTxt = 'engage_expand_implement';
        this.setLabels(labelTxt);
        break;

      case 'augment':
        this.closePopover(text);
        this.showAugment = true;
        labelTxt = 'engage_expand_augment';
        this.setLabels(labelTxt);
        break;
    }
    this.overlay = true;
  }

  closePopover(type) {
    this.showAugment = false;
    this.showElaborate = false;
    this.showImplement = false;
    this.showSugest = false;
    this.overlay = false;
    let labelTxt;
    if (type === 'suggest' ) {
      labelTxt = 'close_engage_suggest_popup';
    } else if (type === 'elaborate') {
      labelTxt = 'close_engage_elaborate_popup';
    } else if (type === 'implement') {
      labelTxt = 'close_engage_implement_popup';
    } else if (type === 'augment') {
      labelTxt = 'close_engage_augment_popup';
    }
    this.setLabels(labelTxt);
  }
  setLabels(labelTxt) {
    const recordId = this.sharedService.getRecordId();
    console.log(labelTxt , 'labelTxt', recordId, 'record id');

    this.saveHistory(labelTxt, 7, recordId);
  }
}
