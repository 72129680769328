import { SharedServiceService } from './../services/shared-service.service';
import { DataServiceService } from './../services/data-service.service';
import { ParentComponent } from '../parent/parent.component';
import { OwlCarouselComponent } from './../owl-carousel/owl-carousel.component';
import { Component,Output,ContentChild, EventEmitter, ViewChild, ElementRef, Renderer2, OnInit, HostListener, AfterViewInit, Input, Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, merge, filter, combineLatest } from 'rxjs/operators';
import { trigger, style, animate, transition, state, query, stagger, keyframes } from '@angular/animations';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare var jQuery: any;

//import {Material} from '../materialcomponent.ts';
//import { EventEmitter } from 'events';
// declare var $: any;
declare const $: any;
declare const ga: any;
declare const yam: any;
declare const Hls: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('100ms', style({ opacity: 1 }))
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('100ms', style({ opacity: 0 }))
        ])
      ]
    ),

    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),
        query(':enter', stagger('200ms', [
          animate('0.5s ease-in', keyframes([
            style({ opacity: 0 }),
            style({ opacity: .5 }),
            style({ opacity: 1 }),
          ]))]), { optional: true })
      ])
    ]),
    trigger('scrollAnimation', [
      state('show', style({
        transform: "translateX(-50%)"
      })),
      state('hide', style({
        transform: "translateX(0%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ])
  ],
})
@Injectable({
  providedIn: 'root'
})
export class HomeComponent extends ParentComponent implements OnInit {
  state = 'hide';
  @ViewChild('right') right: ElementRef;
  @ViewChild('left') left: ElementRef;
  @ViewChild('video') video;
  @ContentChild('innervideo') innervideo;
  @ViewChild('swipeMe') swipeMe: ElementRef;
  @ViewChild('gridList') gridList: ElementRef<any>;
  @Output() modalOpen = new EventEmitter();
  
  index = 1;
  public showRightText = false;
  public showLeftText = false;
  public activeSlide = 0;
  public recordId;
  isInfoPanelVisible: boolean;
  videoDomain: string;
  infoPanelData: any;
  public isVideoPlaying = false;
  filterTags: any;
  loading: boolean;
  solutions: any;
  originalArray: any;
  ltFilterTags: any;
  public isPlaying = false;
  public isPlayingCarousel = false;
  public advice = false;
  show: boolean;
  isDraggingStoped: boolean;
  dragValue: number;
  dragWidth: number;
  dragCount: number;
  public open_cp = false;
  public open_bb = false;
  public open_wm = false;
  public isDemo = false;
  public src;
  public bb_src;
  public wm_src;
  public bb_s_src;
  public mob_max = '768px';
  public max_width = '1400px';
  public min_width = '1401px';
  public cogni_thumb = 'cogni-thumb';
  public homeContent =false;
  public isOpen = false; 
  public build;
  public analysis;
  public implementation;
  public modalData: any;
  public work_src;
  public mySlideOptions = {
      items: 1,
      dots: false,
      autoplay: true,
      autoplayTimeout: 2000,
      autoplayHoverPause: true
  };
  public showSugest = false;
  public showElaborate = false;
  public showImplement = false;
  public showAugment = false;
  public overlay = false;

  tagName: string; 
   
  @Output() carouselOpen = new EventEmitter();   
  @ViewChild('owlElement') owlElement: OwlCarouselComponent;
  constructor(private sharedService: SharedServiceService, public _http: DataServiceService, public shareService: SharedServiceService) {
    super(_http);
  }

  public videoOptions = {
    items: 1,
    dots: false,
    autoplay: false,
    autoplayHoverPause: true,
    video: true
  };

  ngOnInit() {
    this.isDraggingStoped = true;
    this.dragValue = 0;
    this.solutions = [];
    this._http.getAllSolutions().subscribe(resposnse => {
      this.solutions = resposnse;
      this.originalArray = JSON.parse(JSON.stringify(resposnse));
    });

    this.isInfoPanelVisible = false;
    this.videoDomain = this.shareService.getDomain();
    this._http.getAllTags().pipe(
      map(data => ({ data}))
    ).subscribe(response => {
      this.filterTags = response.data;
      // console.log(this.filterTags);
      //this.selectTab(0);
    });
    this._http.getAllLTTags().subscribe(res => {
      this.ltFilterTags = res;
    });

    if (window.outerWidth <= 1500) {
      this.src = 'cp_xl';
      this.bb_src = 'business-banking-xl';
      this.wm_src = 'wm_wrapper_small';
      this.bb_s_src = 'bb-service-catalogue-xl';
      this.cogni_thumb = 'cogni-thumb-xl';
    } else if (window.outerWidth >= 1501) {
      this.src = 'cp_xxl';
      this.wm_src = 'wm_wrapper';
      this.bb_src = 'business-banking-xxl';
      this.bb_s_src = 'bb-service-catalogue-xxl';
      this.cogni_thumb = 'cogni-thumb';
    }

    this.implementation = {
      title: 'Implementation',
      videoUrl: 'implementation.mp4',
      desc: 'Implementation is the last step in the process, after through checking and brainstorming, the MVP is chosen. Next, we move on to the presentation of our solutions to clients or respective internal teams. Showcasing the plethora of potential opportunities we unlock in the Digital Experience Center.',
      images: [
          {
              url: '029.jpg'
          },
          {
              url: '030.jpg'
          },
          {
              url: '031.jpg'
          },
          {
              url: '032.jpg'
          },
          {
              url: '033.jpg'
          },
          {
              url: '034.jpg'
          }
      ]
  };
    
    

    // const moveLeft$ = fromEvent(this.left.nativeElement, 'mousemove');
    // const leaveLeft$ = fromEvent(this.left.nativeElement, 'mouseleave');
    // const enterLeft$ = fromEvent(this.left.nativeElement, 'mouseenter');

    // const moveRight$ = fromEvent(this.right.nativeElement, 'mousemove');
    // const leaveRight$ = fromEvent(this.right.nativeElement, 'mouseleave');
    // const enterRight$ = fromEvent(this.right.nativeElement, 'mouseenter');

    // const enteredLeft$ = enterLeft$.pipe(
    //   map(e => true),
    //   merge(leaveLeft$.pipe(
    //     map(e => false)
    //   ))
    // );


    // const enteredRight$ = enterRight$.pipe(
    //   map(e => true),
    //   merge(leaveRight$.pipe(
    //     map(e => false)
    //   ))
    // );


    // /**
    //  * For Left section mouse over
    //  */
    // moveLeft$.pipe(
    //   combineLatest(enteredLeft$),
    //   filter(([e, b]) => {
    //     return b;
    //   }),
    //   map(([e, _]) => {
    //     return e;
    //   })
    // ).subscribe(e => {

    //   document.querySelector('.right .slide-content').classList.add('blur__filter');
    //   document.querySelector('.right .solid_border').classList.add('blur__filter');
    //   this.showLeftText = true;
    //   this.recordId = this.shareService.getRecordId();
    //   // this.saveHistory(`mouseover_digital_2_0`, 1, this.recordId);
    // });

    // leaveLeft$.subscribe((e) => {
    //   this.showLeftText = false;
    //   document.querySelector('.right .slide-content').classList.remove('blur__filter');
    //   document.querySelector('.right .solid_border').classList.remove('blur__filter');
    // });

    // /**
    //  * Right section Mouse over
    //  */

    // moveRight$.pipe(
    //   combineLatest(enteredRight$),
    //   filter(([e, b]) => {
    //     return b;
    //   }),
    //   map(([e, _]) => {
    //     return e;
    //   })
    // ).subscribe(e => {
    //   document.querySelector('.left .slide-content').classList.add('blur__filter');
    //   document.querySelector('.left .dotted_border').classList.add('blur__filter');
    //   // this.renderer.setStyle(this.left.nativeElement, 'background-image', 'url(assets/images/homepage/img-doingdigital-lg-blur.png)');
    //   this.showRightText = true;
    //   this.recordId = this.shareService.getRecordId();
    //   //  this.saveHistory(`mouseover_being_digital`, 1, this.recordId);
    // });

    // leaveRight$.subscribe((e) => {
    //   document.querySelector('.left .slide-content').classList.remove('blur__filter');
    //   document.querySelector('.left .dotted_border').classList.remove('blur__filter');
    //   this.showRightText = false;
    //   // this.renderer.setStyle(this.left.nativeElement, 'background-image', 'url(assets/images/homepage/img-doingdigital-lg.png)');
    // });

  }

  // onWheel(event: WheelEvent): void {
  //   (<Element>event.target).parentElement.scrollLeft += event.deltaY;
  //   //event.preventDefault();
  // } 

  openModal(data) {
    console.log('model open');
    document.getElementById("lab-visit-modal").style.display = "block"
    this.modalData = data;
    console.log(this.modalData);
    this.isOpen = true;
    console.log(this.isOpen)
    this.recordId = this.sharedService.getRecordId();
    this.saveHistory(data.title, 5, this.recordId);
    this.modalOpen.emit(this.isOpen);
    console.log(this.modalOpen);
    this.showAugment = false;
    this.showElaborate = false;
    this.showImplement = false;
    this.showSugest = false;
    this.overlay = false;
}

  draggingStatus(value) {
    this.isDraggingStoped = value.isEventHappening;
    console.log(this.isDraggingStoped, 'isDraggingStoped');
    this.dragValue = value.dragvalue;
    this.dragWidth = this.gridList.nativeElement.scrollWidth;
   
  }
  dragRight() {
    this.dragCount++;
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`arrow_right`, 8, this.recordId);
    $(this.gridList.nativeElement).animate({
      scrollLeft: this.gridList.nativeElement.scrollLeft + 250
    }, () => {
      this.dragValue = this.gridList.nativeElement.scrollLeft + $(this.gridList.nativeElement).innerWidth();
      console.log(this.dragValue, 'drag value');
      this.dragWidth = this.gridList.nativeElement.scrollWidth;
      console.log(this.dragValue, 'drag width');
    });



  }
  dragLeft() {
    this.dragCount--;
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`arrow_left`, 8, this.recordId);
    $(this.gridList.nativeElement).animate({
      scrollLeft: this.gridList.nativeElement.scrollLeft - 250
    }, () => {
      this.dragValue = this.gridList.nativeElement.scrollLeft;
      this.dragWidth = this.gridList.nativeElement.scrollWidth;
    });

  }
 
  saveLabel(title) {
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(title, 1, this.recordId);
  }

  getData(){
    this.solutions = [];
    this._http.getAllSolutions().subscribe(resposnse => {
      this.solutions = resposnse;
      this.originalArray = JSON.parse(JSON.stringify(resposnse));
    });
  }


  openInfoPanel(index) {
    
    if (this.solutions[index].ext !== true) {
      if (this.isDraggingStoped) {
          this.isInfoPanelVisible = true;
         
          this.recordId = this.shareService.getRecordId();
         //this.saveHistory(`open_catalog_popover_${this.solutions[index].catalogName}`, 8, this.recordId, (index + 1)); 
         this.modalOpen.emit(true);
          // console.log(this.modalOpen);
            this.videoDomain = '';
            this.infoPanelData = this.solutions[index];
           /* if(this.solutions[index].tagName!= 'all'){
            this.tagName = this.solutions.tagName;
            
            console.log('tagname1',this.tagName)
            }*/
           // console.log('open');

            this.videoDomain = this.shareService.getDomain();
            this.infoPanelData = this.solutions[index];
            console.log("info panel data",this.infoPanelData);
          }      
      
    } else {
      window.open(this.solutions[index].videoUrl, '_blank');
    }

  }

  closePopup(data) {
    // document.getElementById("lab-visit-modal").style.display = "none"
    this.isOpen = false;
    this.modalData = null;
    this.modalOpen.emit(this.isOpen);
    // this.video.nativeElement.pause();
    this.saveHistory(`carousel_close_popover_${data}`, 5, this.recordId);
}

  closeInfoPanel(data) {
    // console.log('close');
    if (this.video) {
      this.video.nativeElement.pause();
      this.isPlaying = false;
      this.isVideoPlaying = false;
    }

    const video = Array.prototype.slice.call(document.querySelectorAll('.innerVideos'), 0);
    if (video) {
      video.forEach((ins: HTMLVideoElement) => ins.pause());

    }
    this.isPlaying = false;

    this.isInfoPanelVisible = false;
    this.modalOpen.emit(false);
    this.recordId = this.shareService.getRecordId();
    this.open_cp = false;
    this.open_bb = false;
    this.open_wm = false;
   this.saveHistory(`close_popover_${data}`, 8, this.recordId);
    this.advice = false;
    this.state = "hide";
  }

  playPause(name = false, url, id) {
    const video = <HTMLVideoElement>document.getElementById('singleVideo1');
    var elem = document.getElementById("singleVideo1");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } 
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = url;
      video.addEventListener('loadedmetadata', function () {
        video.play();
      });
    }
  
    if (name) {
      this.recordId = this.shareService.getRecordId();
      this.saveHistory(`play_video_${name}`, 8, this.recordId);

      ga('send', {
        hitType: 'event',
        eventCategory: 'Videos',
        eventAction: 'play',
        eventLabel: name
      });
    }
    const data = {
      'request': {
        'userId': this.shareService.getUserId(),
        'mailerFlag': 'false',
        'timeStamp': Date.now(),
        'videoId': id
      }

    }
    this._http.saveVideoInfo(data).subscribe(res => { });

  }

  playAndPause(id) {
    const video = <HTMLVideoElement>document.getElementById('innerVideos_' + id);
    if (video.paused) {
      this.isPlayingCarousel = true;
      video.play();
    } else {
      this.isPlayingCarousel = false;
      video.pause();
    }
  }

  moreCP() {
    this.open_cp = !this.open_cp;
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`read_more_cp`, 8, this.recordId);
    this.getParameterByName('demo', null).subscribe(res => {
      this.isDemo = res;
    });
  }
  moreBB() {
    this.open_bb = !this.open_bb;
    this.getParameterByName('demo', null).subscribe(res => {
      this.isDemo = res;
    });


    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`read_more_bb`, 8, this.recordId);
  }
  moreWM() {
    this.open_wm = !this.open_wm;
    this.getParameterByName('demo', null).subscribe(res => {
      this.isDemo = res;
    });


    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`read_more_wm`, 8, this.recordId);
  }

  openCP() {
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`view_demo_cp`, 8, this.recordId);
  }

  openBB() {
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`view_demo_bb`, 8, this.recordId);
  }

  moveSection() {
    this.advice = !this.advice;
    this.state = 'show';
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`open_corporate_banking_catalogue`, 8, this.recordId);
  }

  closeExtrapanel() {
    this.state = 'hide';
    this.recordId = this.shareService.getRecordId();
    this.saveHistory(`close_corporate_banking_catalogue`, 8, this.recordId);
  }


  toggleCP(label) {
    this.advice = !this.advice;
    this.state = 'hide';
    const recordId = this.shareService.getRecordId();
    this.saveHistory(label, 8, recordId);
  }

  magnify(imgID, zoom) {
    if (!this.isDemo) {
      return;
    }
    let img, glass, w, h, bw;
    img = document.getElementById(imgID);

    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:
     position: absolute;
                border: 3px solid #000;
                border-radius: 50%;
                cursor: none;
                width: 100px;
                height: 100px;
    */
    img.parentNode.parentNode.style.width = img.width + 'px';
    img.parentNode.parentNode.style.margin = '0 auto';
    img.parentNode.parentNode.style.position = 'relative';
    const src = img.src.split('/');
    const img_path = `../app/${src[src.length - 2]}/${src[src.length - 1]}`;
    glass.style.backgroundImage = `url(${img_path})`;
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    glass.style.width = '150px';
    glass.style.height = '150px';
    // glass.style.border = '3px solid #000';
    glass.style.borderRadius = '50%';
    glass.style.position = 'absolute';
    glass.style.backgroundColor = '#FFF';
    // glass.style.setProperty("cursor", "none", "important");
    // img.style.setProperty("cursor", "none", "important");


    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
      let pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);

      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
      if (x < w / zoom) { x = w / zoom; }
      if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
      if (y < h / zoom) { y = h / zoom; }
      /*set the position of the magnifier glass:*/
      glass.style.left = (x - w) + "px";
      glass.style.top = (y - h) + "px";
      /*display what the magnifier glass "sees":*/
      glass.style.backgroundPosition = "-" + (((x * zoom) - w + (bw))) + "px -" + ((y * zoom) - h + bw) + "px";
    }
    function getCursorPos(e) {
      let a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }


}