import { DataServiceService } from './../services/data-service.service';
import { ElementService } from './../services/element.service';
import { Directive, Input, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

@Directive({
  selector: '[appCounter]'
})
export class CounterDirective implements AfterViewInit {

  @Input() from: number;
  @Input() to: number;
  @Input() speed: number;
  public refreshInterval = 100;
  private obs = new Subject();
  public obs$ = this.obs.asObservable();
  constructor(private el: ElementRef, private elementService: ElementService, private dataService: DataServiceService) {

  }

  ngAfterViewInit() {
    const loops = Math.ceil(this.speed / this.refreshInterval);
    const increment = (this.to - this.from) / loops;
    let loopCount = 0;
    const myCounter = this.dataService.obs$.pipe(
      mergeMap(() => {
        return interval(this.refreshInterval);
      })
    ).subscribe((res) => {
      this.from += increment;
      loopCount++;
      this.el.nativeElement.innerHTML = `${Math.round(this.from)}+`;
      if (loopCount >= loops) {
        myCounter.unsubscribe();
        this.from = this.to;
      }
    });

  }

  @HostListener('window:scroll', ['$event'])
  onScrollCallback() {
    const section = document.querySelector('.jumbotron_journey');
    if (this.elementService.isInViewport(section)) {
      this.obs.next();
    }
  }

}
