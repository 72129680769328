import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-contactform",
  templateUrl: "./contactform.component.html",
  styleUrls: ["./contactform.component.css"],
})
export class ContactformComponent implements OnInit {
  data: any = {};
  name = "";
  email = "";
  formData: any = {};
  result: any = {};
  url = "";
  alert: boolean = false;
  alertsuccess: boolean = false;
  alerterror: boolean = false;
  message = "";
  @Output() close = new EventEmitter<string>();
  contactvalidation = new FormGroup({
    empid: new FormControl("", Validators.required),
    empname: new FormControl("", Validators.required),
    account: new FormControl("", Validators.required),
    projectname: new FormControl("", Validators.required),
  });

  constructor(private http: HttpClient) {}
  ngOnInit() {}

  //open download file in new tab
  download(url) {
    var path = url;
    window.open(path, "Download");
  }

  /* getFileUrl() {
     this.http.get("https://gibhdey937.execute-api.us-east-1.amazonaws.com/test?filename=" + AppComponent.selectedCardName).subscribe(response => {
       this.result = response;
       this.url = JSON.stringify(this.result.message)
       this.download(this.result.message);
 
     })
   }*/

  onSubmit() {
    if (this.contactvalidation.valid) {
      //to post data to api gateway
      this.formData = {
        formName: "Contactus",
        empId: this.contactvalidation.get("empid").value,
        empName: this.contactvalidation.get("empname").value,
        accountName: this.contactvalidation.get("account").value,
        projectName: this.contactvalidation.get("projectname").value,
        pov: AppComponent.selectedCardName,
      };

      //to post the details in dynamodb

      this.http
        .post<any>(
          "https://gag6jl5kei.execute-api.us-east-1.amazonaws.com/prod/portalDB",
          this.formData
        )
        .subscribe(
          (response) => {
            this.result = response;
            //console.log(this.result);
            if (!this.result.message) {
              console.log("Error Occured", this.result.message);
              this.alerterror = true;
              this.closeAlertSuccess();
              this.message = "Something went wrong,Please try again later";
            } else {
              this.alertsuccess = true;
              this.message =
                "Form Submitted Successfully! We will contact you shortly.";
              this.closeAlertError();
              this.download(this.result.message);
            }
          },
          (error) => {
            console.log("Error Occured", this.result.message);
            this.alerterror = true;
            this.closeAlertSuccess();
            this.message = "Something went wrong,Please try again later";
          }
        );

      setTimeout(() => {
        this.close.emit();
      }, 4000);
    } else {
      console.log("form not valid");
    }
  }
  closeAlertSuccess() {
    this.alertsuccess = false;
  }
  closeAlertError() {
    this.alerterror = false;
  }
}
