import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { SharedServiceService } from './../services/shared-service.service';
import { DataServiceService } from '../services/data-service.service';
import { ParentComponent } from '../parent/parent.component';
declare const Hls: any;
@Component({
  selector: 'app-video-carousel',
  templateUrl: './video-carousel.component.html',
  styleUrls: ['./video-carousel.component.scss'],
})
export class VideoCarouselComponent extends ParentComponent implements OnInit, AfterViewInit {
  videos: any;
  videoDomain: string;
  videoPlayerWidth: any;
  transletValue = 0;
  currentIndex = 0;
  public video_title;
  public video_count;
  title: any;
  public isPlayingCarousel = false;

  constructor(private sharedService: SharedServiceService, public renderer: Renderer2, public dataService: DataServiceService) {
    super(dataService);
  }
  @Input() set options(options) {
    this.videos = options;
    this.video_count = options.length;
    this.video_title = options[0].title;
  }

  @Input() set titleText(titleText) {
    this.title = titleText;
  }
  @ViewChild('videoCaraousalContainer') videoCaraousalContainer: ElementRef<any>;
  @ViewChild('video') video;
  ngOnInit() {
    this.videoDomain = this.sharedService.getDomain();
    this.videoPlayerWidth = this.videoCaraousalContainer.nativeElement.offsetWidth;
  }
  ngAfterViewInit() {
    this.loadvideo('innerVideos_0', this.videos[0]);
    Array.from(document.querySelectorAll('.innerVideos')).forEach((v, i) => {
      const video = v;
      video.addEventListener('play', () => {
        console.log('video source:', this.video_title);
        const recordId = this.sharedService.getRecordId();
        this.saveHistory(`play_video_${this.video_title}`, 8, recordId);
      });
    });
  }

  goRight() {
    const video = Array.prototype.slice.call(document.querySelectorAll('.innerVideos'), 0);
    if (video) {
      video.forEach((ins: HTMLVideoElement) => ins.pause());
    }
    if (this.currentIndex < (this.videos.length - 1)) {
      this.currentIndex++;
      this.video_title = this.videos[this.currentIndex].title;
      this.transletValue = this.transletValue - this.videoPlayerWidth;
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`video_move_right`, 8, recordId);
      this.loadvideo(`innerVideos_${this.currentIndex}`, this.videos[this.currentIndex]);
    }
  }
  goLeft() {
    const video = Array.prototype.slice.call(document.querySelectorAll('.innerVideos'), 0);
    if (video) {
      video.forEach((ins: HTMLVideoElement) => ins.pause());

    }
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.video_title = this.videos[this.currentIndex].title;
      this.transletValue = this.transletValue + this.videoPlayerWidth;
      const recordId = this.sharedService.getRecordId();
      this.saveHistory(`video_move_left`, 8, recordId);
      this.loadvideo(`innerVideos_${this.currentIndex}`, this.videos[this.currentIndex]);
    }
  }

  loadvideo(id, video_details) {
    const video = <HTMLVideoElement>document.getElementById(id);
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(video_details.url);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        // video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = video_details.url;
      video.addEventListener('loadedmetadata', function () {
        // video.play();
      });
    }
    const data = {
      'request': {
        'userId': this.sharedService.getUserId(),
        'mailerFlag': 'false',
        'timeStamp': Date.now(),
        'videoId': video_details.id
      }

    }
    this.dataService.saveVideoInfo(data).subscribe(res => console.log);
  }

}
