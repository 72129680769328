import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthWithOAuthService } from 'src/app/services/oauth/auth-with-oauth.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authWithOAuth: AuthWithOAuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {

    if (this.activatedRoute.snapshot.queryParams['code']) {
      this.spinner.show();
      this.activatedRoute.queryParams.subscribe((params) => {
        console.log("params: ", params);
        console.log(this.authWithOAuth.getBearerTokenAzure(params.code, ''))
        if (window.opener) {
          // send them to the opening window
        console.log("window.opener: ", window.opener);
          window.opener.postMessage(params, window.location.origin);
          // close the popup
          window.close();
        } else {
          // this.spinner.show();
          console.log("else: ");
          this.authWithOAuth.getBearerTokenAzure(params.code, '');

          //this.appauthLibrary.getIdToken(signInRedirectUri, authCodeuri);

          this.authWithOAuth.token.subscribe((dataval) => {
            this.spinner.hide();
            if (dataval != undefined) {
              if (this.authWithOAuth.getIdToken()) {
              } else {
              }
            }
          });
        }
      });
    }
  }

  ngAfterViewInit(){
    this.spinner.show();
}

}
