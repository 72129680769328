import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookalabComponent } from '../bookalab/bookalab.component';
import { OwlCarouselComponent } from '../owl-carousel/owl-carousel.component';
import { MainModule } from './../main/main.module';
import { SharedModule } from '../shared/shared.module';
import { MatDatepickerModule } from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import { MatTableModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatStepperModule
    // MainModule
  ],
  declarations: [
    HomeComponent,
    BookalabComponent,
    //OwlCarouselComponent
  ],
  exports: [HomeComponent, MatFormFieldModule,
    MatInputModule, ReactiveFormsModule]
})
export class HomeModule { }