import { SharedServiceService } from './../services/shared-service.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  public mob_max = '768px';
  public max_width = '1500px';
  public min_width = '1501px';
  public videoUrl = this.sharedService.getDomain();
  @ViewChild('img') img: ElementRef;
  public src;
  constructor(private sharedService: SharedServiceService) { }

  ngOnInit() {
    if (window.outerWidth <= 1500) {
      this.src = 'video_md';
    } else if (window.outerWidth >= 1501) {
      this.src = 'video_xl';
    }
  }

}
